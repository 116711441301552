import React, { useState } from "react";
// import RichTextEditor fr./MessageScreen
import { Typography } from "@material-tailwind/react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import InfoPopup from "../../Components/Popup/InfoPopup";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { MessageContent } from "../../Utils/Constant";
import { useBlockUser } from "../../Web-Hooks/Profile/use-profile";
import MessageInput from "./MessageInput";
import MessageMenu from "./MessageMenu";
import MessageScreen from "./MessageScreen";

const Message = () => {
  const location = useLocation();
  const { pathname, state } = location;
  const { user } = state;
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { mutate: blockUser, isPending: blockUserLoading } = useBlockUser();
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const CitationOption = [
    {
      id: 1,
      label: "Clear Chat",
      value: "clear",
      handleClick: () => {
        console.log("Clear Chat");
      },
    },
    {
      id: 2,
      label: "Block User",
      value: "block",
      handleClick: () => {
        setConfirmationPopup(true);
      },
    },
  ];

  const handleBlockUser = () => {
    try {
      const blockBody = {
        BlockedUserId: user?.userId,
        BlockFrom: "UserToUser",
        // BlockFrom: "DebateWise",
        WorkSpaceId: selectedWorkspace?.workSpaceId,
        BlockedByUserId: loggedInUser?.userId,
        // DebateId: "7f691cb3-5c7d-436f-b174-b58442e900b4",
      };
      const res = blockUser(blockBody);
      if (res) {
        toast.success("User blocked successfully", "block");
      }
    } catch (error) {
      console.log("Something went wrong", "error");
    }
  };

  return (
    <section className="grid grid-rows-[auto_1fr] gap-0">
      <InfoPopup
        open={confirmationPopup}
        btn1={"Cancel"}
        btn2={"Block"}
        loading2={blockUserLoading}
        onClickBtn2={handleBlockUser}
        onClickBtn1={() => setConfirmationPopup(false)}
        title="Block User"
        description="Are you sure you want to block this user?"
        handleClose={() => setConfirmationPopup(false)}
      />
      <div className="sticky top-0 bg-white z-10">
        <div className="px-3 md:px-8 h-12 md:h-16 flex justify-between items-center">
          <Typography variant="h4" className="text-2xl ">
            Sage
          </Typography>
          <div className="relative">
            <MessageMenu options={CitationOption}/>
          </div>
        </div>
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      </div>
      <div className="flex w-full flex-col gap-0 h-[calc(100vh-10.5rem)] md:h-[calc(100vh-8.5rem)] ">
        <div className="overflow-y-auto p-6 h-full">
          <MessageScreen user={pathname} messageContent={MessageContent} />
        </div>
        <div className="px-6 pb-2 bg-transparent">
          <MessageInput />
        </div>
      </div>
    </section>
  );
};

export default Message;
