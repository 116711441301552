import React from "react";
import { ReactComponent as DoubleChecked } from "../../Assets/Svg/DoubleChecked.svg";
import { Avatar } from "@material-tailwind/react";
import { useFileViewer } from "../../Context/FileViewerContext";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import VIDImage from "../../Assets/Images/video.png";
import URLImage from "../../Assets/Images/url.png";

const MessageCard = ({ message }) => {
    const {handleFile} = useFileViewer();
    const fileType = message?.type;
    const Src =
  fileType === "document"
    ? DocImage
    : fileType === "pdf"
    ? PDFImage
    : message?.type === "url"
    ? URLImage
    : fileType === "video"
    ? VIDImage
    : message?.text;
  return (
    <div
      className={`message-container ${
        message.sender === "user" ? "justify-end" : "justify-start"
      }`}
      key={message.messageId}
    >
      {message.sender !== "user" && (
        <div className="shrink-0 mr-4">
          <Avatar
            src="https://docs.material-tailwind.com/img/face-2.jpg"
            alt="avatar"
            size="sm"
          />
        </div>
      )}
      <p
        className={`message-bubble  ${
          message.sender === "user" ? "sent" : "received"
        }`}
      >
        {message.type !== "text" ? (
          <img src={Src} className={`inline ${message.type === "image" ? "max-w-[300px] max-h-[300px]" : "max-w-[100px] max-h-[100px]"} cursor-zoom-in`} alt="" onClick={()=>{
            handleFile({
              fileUrl: message.text,
              fileType: message.type,
              fileName: message.text?.split("/").pop(),
              createdBy: message.sender  
            })
          }} />
        ) : (
          message.text
        )}{" "}
        <sub className="flex flex-row ml-2 gap-1 justify-end items-end py-1 shrink-0">
          <span className="text-xs">{message.messageTime}</span>
          {message.status === "read" && <DoubleChecked className=" w-4 h-4 " />}
        </sub>
      </p>
    </div>
  );
};

export default MessageCard;
