import {
  AcademicCapIcon,
  BuildingOffice2Icon,
  EllipsisHorizontalIcon,
  MusicalNoteIcon,
} from "@heroicons/react/24/outline";
// import UpgradPlan from "../Assets/Svg/Dollar.svg";
import { ReactComponent as EditPencil } from "../Assets/Svg/EditPencil.svg";
import Logout from "../Assets/Svg/Logout.svg";
// import Message from "../Assets/Svg/Message.svg";
import Plus from "../Assets/Svg/Plus.svg";
import Profile from "../Assets/Svg/Profile.svg";
import { ReactComponent as Redebate } from "../Assets/Svg/Redebate.svg";
import { ReactComponent as Tag } from "../Assets/Svg/Tag.svg";
import { ReactComponent as share } from "../Assets/Svg/share.svg";
import { ReactComponent as voteIcon } from "../Assets/Svg/voted.svg";
import Setting from "../Assets/Svg/Setting.svg";
import { BookmarkIcon as BookmarkFilledIcon } from "@heroicons/react/24/outline";
import { BookmarkIcon } from "@heroicons/react/24/solid";
import Library from "../Assets/Svg/Library.svg";
import Role from "../Assets/Svg/Role.svg";
import SearchIcon from "../Assets/Svg/SearchIcon.svg";
// import Setting from "../Assets/Svg/Setting.svg";

export const WorkSpaces = [
  {
    profile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Parth Shinde",
    member: 8,
  },
  {
    profile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Novus Code",
    member: 8,
  },
  {
    profile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Debate Base",
    member: 8,
  },
];

export const REPORTS_MESSAGES = [
  {
    id: 1,
    message: "It's spam",
  },
  {
    id: 2,
    message: "Nudity or sexual activity",
  },
  {
    id: 3,
    message: "I just don't like",
  },
  {
    id: 4,
    message: "Information is wrong",
  },
  {
    id: 5,
    message: "Scam or fraud",
  },
  {
    id: 6,
    message: "Violence or dangerous organisations",
  },
  {
    id: 7,
    message: "Suicide or self-injury",
  },
  {
    id: 8,
    message: "Something else",
    value: "other",
  },
];

export const USERS = [
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Parth Shinde",
    follower: 150,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "John Smith",
    follower: 9,
    isFollowing: false,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Json Carl",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Ket Perry",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Brim",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Reaper",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Adarsh",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Ben Brooks",
    follower: 20,
    isFollowing: true,
  },
  {
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    name: "Grass Bruse",
    follower: 20,
    isFollowing: true,
  },
];

export const MessageContent = [
  {
    id: 1,
    date: "2024-02-25",
    messageDetails: [
      {
        messageId: 1,
        text: "Hello",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",
      },
      {
        messageId: 2,
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        sender: "recipient",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 3,
        text: "Howe are you?",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 4,
        text: "Howe are you?",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 5,
        text: "Howe are you?",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 6,
        text: "Howe are you?",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 7,
        text: "Howe are you?",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
    ],
  },
  {
    id: 2,
    date: "2024-02-26",
    messageDetails: [
      {
        messageId: 1,
        text: "Hello",
        sender: "user",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 2,
        text: "Hello",
        sender: "recipient",
        status: "read",
        messageDate: "02/26/2024",
        messageTime: "2:20 AM",
        type: "text",

      },
      {
        messageId: 3,
        text: "I want to join Office-party group ",
        sender: "user",
        status: "read",
        messageTime: "3:20 AM",
        type: "text",

      },
      {
        messageId: 4,
        text: "Okay",
        sender: "recipient",
        status: "read",
        messageTime: "3:20 AM",
        type: "text",

      },
      {
        messageId: 8,
        text: "https://devsaasimgstorage.blob.core.windows.net/test/47a12d53-78cc-4e16-bfef-252f6931cda9/f4e66a15-0087-418a-8b08-e3dd679058af/772c7774-4c95-46bf-a51e-8ad9287aa11f/1731482076945-83dd4eda-1abc-49de-9fe0-ee783990ad30.png",
        sender: "recipient",
        status: "read",
        messageTime: "3:20 AM",
        type: "image",

      },
      {
        messageId: 5,
        text: "https://devsaasimgstorage.blob.core.windows.net/test/47a12d53-78cc-4e16-bfef-252f6931cda9/f4e66a15-0087-418a-8b08-e3dd679058af/45cc8295-c05e-4ae2-ad51-e9c40c54281b/4c9b95e2-e397-445f-8426-b38d5c0d2238_sample.pdf",
        sender: "user",
        status: "read",
        messageTime: "3:20 AM",
        type: "pdf",

      },
      {
        messageId: 6,
        text: "https://devsaasimgstorage.blob.core.windows.net/debatemodule/e75f59fc-e2e2-4f53-be95-68fe0d117e62_file-sample_100kB.doc",
        sender: "user",
        status: "read",
        messageTime: "3:20 AM",
        type: "document",

      },
      {
        messageId: 7,
        text: "https://devsaasimgstorage.blob.core.windows.net/debatemodule/57d7d749-0575-4165-b1b6-ade61d5e48b5_baby_-_105201(540p)__29dc0e26-5627-4b40-83fb-db79959c2829.mp4",
        sender: "user",
        status: "read",
        messageTime: "3:20 AM",
        type: "video",

      },
    ],
  },
];

export const POSTS = [
  {
    id: "1",
    createdAt: new Date(),
    name: "Parth Shinde",
    accountPreference: "public",
    content: `&quot;I found solution to all my design needs from Creative
                  Tim. I use them as a freelancer in my hobby projects for fun!
                  And its really affordable, very humble guys !!!&quot;`,
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
  },
  {
    id: "2",
    createdAt: new Date(),
    name: "Ket Perry",
    accountPreference: "private",
    content: `&quot;I found solution to all my design needs from Creative
                    Tim. I use them as a freelancer in my hobby projects for fun!
                    And its really affordable, very humble guys !!!&quot;`,
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
  },
  {
    id: "3",
    createdAt: new Date(),
    name: "John Smith",
    accountPreference: "public",
    content: `&quot;I found solution to all my design needs from Creative
                    Tim. I use them as a freelancer in my hobby projects for fun!
                    And its really affordable, very humble guys !!!&quot;`,
    profilePicture:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
  },
];
export const DebateCategories = [
  {
    SVG: AcademicCapIcon,
    Title: "Eductaion",
  },

  {
    SVG: BuildingOffice2Icon,
    Title: "Business",
  },
  {
    SVG: MusicalNoteIcon,
    Title: "Uncategories",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Outdoors",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Government",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Economy",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Sports",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Military",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Weather",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Environment",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Technology",
  },

  {
    SVG: AcademicCapIcon,
    Title: "Fitness",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Religion",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Science",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Society",
  },
  {
    SVG: AcademicCapIcon,
    Title: "Politics",
  },
  {
    SVG: EllipsisHorizontalIcon,
    Title: "Other",
  },
];
export const NotifiactionOptions = [
  {
    opt: "Option 1",
  },
  {
    opt: "Option 2",
  },
  {
    opt: "Option 3",
  },
  {
    opt: "Option 4",
  },
];

export const PostActionIcon = [
  {
    SVG: Redebate,
    action: "redebate",
    count: 0,
    toolcontent: "Redeabate",
    isSvg: true,
  },
  {
    SVG: Tag,
    action: "tagCitation",
    count: 0,
    toolcontent: "Tag someone",
    isSvg: true,
  },
  {
    SVG: voteIcon,
    action: "vote",
    count: 0,
    toolcontent: "Vote",
    isSvg: true,
  },
  {
    SVG: BookmarkIcon,
    SVGFilled: BookmarkFilledIcon,
    action: "vote",
    count: 0,
    toolcontent: "Vote",
    isSvg: true,
  },
  {
    SVG: share,
    action: "share",
    count: null,
    toolcontent: "share",
    isSvg: true,
  },
];

// eslint-disable-next-line
export const ProfileMenuOptions = [
  // {
  //   SVG: UpgradPlan,
  //   item: "Upgrade Plan",
  //   isHRline: false,
  //   route: "create-debate/add-plan",
  // },
  {
    SVG: Plus,
    item: "Start Debate",
    isHRline: false,
    route: "create-debate",
  },
  // {
  //   SVG: SearchIcon,
  //   item: "Find Motion",
  //   isHRline: false,
  //   route: "find-motion",
  // },
  // {
  //   SVG: Plus,
  //   item: "Suggest Motion",
  //   isHRline: false,
  //   route: "suggest-motion",
  // },
  {
    SVG: Library,
    item: "Library",
    isHRline: false,
    route: "library",
  },
  {
    SVG: Role,
    item: "Role Management",
    isHRline: false,
    route: "role",
  },
  // {
  //   SVG: Message,
  //   item: "Message",
  //   isHRline: false,
  //   route: "message",
  //   isPopup: true,
  // },
  {
    SVG: Profile,
    item: "Profile",
    isHRline: false,
    route: "profile",
    isPopup: true,
  },
  {
    SVG: SearchIcon,
    item: "Search",
    isHRline: false,
    route: "search",
  },
  {               //TODO: Feature Rendering REACT_APP_NOTIFICATION_SETTING_MODULE
    SVG: Setting,
    item: "Settings",
    isHRline: false,
    route: "setting",
  },
  // {
  //   SVG: RateUs,
  //   item: "Rate us",
  //   isHRline: false,
  //   route: "rate-us",
  // },
  {
    SVG: Logout,
    item: "Logout",
    isHRline: false,
    route: "/",
  },
];

export const LibrarySortByOptions = [
  {
    label: "Most Recent",
    value: "",
  },
  {
    label: "URLs",
    value: ["url"],
  },
  {
    label: "Documents",
    value: ["doc", "docx", "pdf"],
  },
  {
    label: "Photos",
    value: ["png", "jpg", "jpeg", "webp","image","svg"],
  },
  {
    label: "Videos",
    value: ["mp4", "mkv", "webm", "mp3", "wav"],
  },
];

export const PublicFeedBarOptions = [
  {
    option: "Feed ",
    optionLabel: "(Public)",
    subOption: false,
    route: "/feed-public",
  },
  {
    option: "Debated",
    optionLabel: "(Public)",
    subOption: true,
    route: "/debated-public",
  },
  {
    option: "Voted",
    optionLabel: "(Public)",
    subOption: true,
    route: "/voted-public",
  },
];

export const FeedBarOptions = [
  {
    option: "Feed",
    subOption: false,
    route: "/feed",
  },
  {
    option: "Debated",
    subOption: true,
    route: "/debated",
  },
  {
    option: "Voted",
    subOption: true,
    route: "/voted",
  },
  {
    option: "Private",
    subOption: true,
    route: "/private",
  },
  {
    option: "News",
    subOption: false,
    route: "/news",
  },
];

export const DirectMessageOption = [
  {
    option: (
      <div className="flex flex-row justify-between items-center">
        <p className="">Direct Message </p>
        <EditPencil className="w-5 h-5 " />
      </div>
    ),
    subOption: true,
    route: "/message",
  },
];

export const NEWS = [
  {
    title: "Breaking News",
    body: [
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
    ],
    authors: [
      {
        uri: "pierluigi_paganini@securityaffairs.com",
        name: "Pierluigi Paganini",
        type: "author",
        isAgency: false,
      },
    ],
    category: "sports",
    link: "https://stackoverflow.com/questions/72116270/react-router-dom-v6-hover-styles-on-active-nav",
    createdAt: new Date(),
    city: "Mumbai",
  },
  {
    title: "Debates",
    body: [
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
    ],
    authors: [
      {
        uri: "pierluigi_paganini@securityaffairs.com",
        name: "Pierluigi Paganini",
        type: "author",
        isAgency: true,
      },
    ],
    category: "lifestyle",
    link: "https://stackoverflow.com/questions/72116270/react-router-dom-v6-hover-styles-on-active-nav",
    createdAt: new Date(),
    city: "Gujarat",
  },
  {
    title: "New Link",
    body: [
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
      "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
    ],
    authors: [
      {
        uri: "pierluigi_paganini@securityaffairs.com",
        name: "Pierluigi Paganini",
        type: "author",
        isAgency: false,
      },
    ],
    category: "politics",
    link: "https://stackoverflow.com/questions/72116270/react-router-dom-v6-hover-styles-on-active-nav",
    createdAt: new Date(),
    city: "Uttar Pradesh",
  },
  {
    title: "New Link",
    body: "Russia-linked Turla APT uses new TinyTurla-NG backdoor to spy on Polish NGOs US Gov dismantled the Moobot botnet controlled by Russia-linked APT28 A cyberattack halted operations at Varta production plants North Korea-linked actors breached the emails of a Presidential Office member CISA adds ...",
    authors: [
      {
        uri: "pierluigi_paganini@securityaffairs.com",
        name: "Pierluigi Paganini",
        type: "author",
        isAgency: false,
      },
    ],
    category: "viral",
    link: "https://stackoverflow.com/questions/72116270/react-router-dom-v6-hover-styles-on-active-nav",
    createdAt: new Date(),
    city: "Uttrakhand",
  },
];

export const LoginInfo = {
  headLine: "First of all, enter your email address",
  subLine: "We suggest using the email address that you use at work.",
  footerOpt: [
    {
      opt: "Terms & Conditions",
      value: "privacy",
      link: process.env.REACT_APP_TERMS_SERVICE,
    },
    {
      opt: "Contact us",
      value: "contact",
      link: process.env.REACT_APP_CONTACT,
    },
    {
      opt: "Choose Country",
      // subOpt: COUNTRIES
    },
  ],
};

export const OTPInfo = {
  headLine: "Check your email for a code",
  subLine: "We’ve sent 6-character code to ",
  subLine2: ". The code expires shortly. So please enter it soon.",
  footerOpt: [
    {
      opt: "Privacy & terms",
    },
    {
      opt: "Contact us",
    },
  ],
};

export const COUNTRIES = [
  {
    name: "India",
    flag: "",
  },
  {
    name: "United States",
    flag: "",
  },
  {
    name: "Brazil",
    flag: "",
  },
  {
    name: "Australia",
    flag: "",
  },
  {
    name: "Germany",
    flag: "",
  },
];

export const findCode = "Can't find your code? Check your spam folder?";

export const OTPCode = [
  {
    otp: 0,
  },
  {
    otp: 0,
  },
  {
    otp: 0,
  },
  {
    otp: 0,
  },
  {
    otp: 0,
  },
  {
    otp: 0,
  },
];

export const ResetOTP = [
  {
    msg: "Still not received OTP?",
  },
  {
    msg: "Resend OTP",
  },
];
// Tooltip design
export const TooltipStyle = "text-md cursor-pointer";

// Tooltip design
export const hoverTextStyle = "cursor-pointer hover:text-black bg-none";

export const hoverSidebarStyle = "hover:bg-primary focus:font-semibold";
// NovuscodeUser
export const NovusCode = {
  username: "Novuscode",
  email: "Novuscode@gmail.com",
  Initials: "NC",
};

export const otpInputStyleError = "!border-red-500";

export const otpInputStyleNormal = "!border-primary";

export const otpInputStyleSuccess = "!border-green-500";

export const NewsTabData = [
  {
    label: "All",
    value: "all",
    desc: NEWS,
  },
  {
    label: "Sports",
    value: "sports",
    desc: NEWS,
  },
  {
    label: "Business",
    value: "business",
    desc: NEWS,
  },
  {
    label: "Education",
    value: "education",
    desc: NEWS,
  },
  {
    label: "Politics",
    value: "politics",
    desc: NEWS,
  },
  {
    label: "Lifestyle",
    value: "lifestyle",
    desc: NEWS,
  },
  {
    label: "Viral",
    value: "viral",
    desc: NEWS,
  },
  {
    label: "Crime",
    value: "crime",
    desc: NEWS,
  },
];

export const CITIES = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Gujarat",
    value: "IND",
  },
  {
    label: "Uttar Pradesh",
    value: "UP",
  },
  {
    label: "Uttrakhand",
    value: "UK",
  },
  {
    label: "Bihar",
    value: "BH",
  },
  {
    label: "Tamilnadu",
    value: "TM",
  },
  {
    label: "Mumbai",
    value: "BMB",
  },
];

export const WorkSpaceInfo = {
  headLine: "Welcome to the Debate!",
  subLine:
    "A debatebase gives your team a dedicated space for productive debate — a workspace designed for meaningful discussions.Ready to kick off a new debate? Click the button below to create your debatebase!",
  footerOpt: [
    {
      opt: "Privacy & terms",
    },
    {
      opt: "Contact us",
    },
    {
      opt: "Choose Region",
      // subOpt:COUNTRIES
    },
  ],
};

export const smallSizeBorder =
  'after:content-[""] after:h-[80%] after:w-px after:absolute after:right-0 after:top-[10%] after:bg-gray-700';

export const NOTIFICATION = [
  {
    header: "MR_YASH_! Mute Argument",
    detail: "MR_YASH_! Mute argument on debatebase music play group",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "received",
  },
  {
    header: "You Invited @hardikdangar to debate:",
    detail: "Rohit is a good human",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "sent",
  },
  {
    header: "You Invited HD to Debate:",
    detail: "Election 2024 is coming",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "sent",
  },
  {
    header: "You Invited Vipul_99 to Debate:",
    detail: "Shopping#mumba who join us tell me",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "sent",
  },
  {
    header: "Pooja_Saumya Want to Participate Debate",
    detail: "Yash_Dangar Mute argument on debatebase music play group",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "received",
  },
  {
    header: "Joy__1992 Mute Argument",
    detail: "Joy__1992 Mute argument on debatebase music play group",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "nominated",
    subType: "received",
  },
  {
    header: "Hardik Added in Group",
    detail: "Hardik add you in Novuscode22 group plus other 50 members also",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "notification",
  },
  {
    header: "Created Debates Reminder",
    detail:
      "Hey @Gautam you have created a debate Election-2024, Hurry up nominated a user wants...",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "notification",
  },
  {
    header: "Pooja Wants Debate",
    detail:
      "Hey @Gautam you have created a debate Indian_army, hurry up nominated a user wants...",
    userProfile:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80",
    type: "notification",
  },
];

export const NotificationTabs = [
  {
    label: "Nomination",
    value: "nomination",
    subTabs: [
      { label: "Received", value: "get" },
      { label: "Sent", value: "sent" },
    ],
  },
  {
    label: "Notification",
    value: "notification",
  },
];
// checked svg
export const ReadChecked = (
  <svg
    width="18"
    height="10"
    viewBox="0 0 18 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7931 1.00133L4.63338 8.87983L1.142 5.54052"
      stroke="#81E299"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.7397 1.00055L9.57958 8.87947L6.98346 6.42058"
      stroke="#81E299"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const DebateHeaderInfo = [
  {
    headLine: "Create a Debate",
    baseLine:
      "Groups are a way to organize your conversations. Below are some suggestions to get you started.",
    extraDetail: [
      {
        opt: "Blitz Debate",
        subOpt: "",
        value: "blitz",
      },
      {
        opt: "Standard Debate",
        subOpt: "",
        value: "standard",
      },
      {
        opt: "Custom Debate",
        subOpt: "",
        value: "custom",
      },
    ],
    page: 1,
    pageName: "DebateType",
  },
  {
    headLine: "Create a Debate",
    baseLine: "Office-party",
    page: 2,
    extraDetail: [
      {
        opt: "Public-anyone in Novuscode",
        subOpt: "",
        value: "public",
      },
      {
        opt: "Semi-Public - anyone in Sub Debatebase",
        subOpt: "",
        value: "semipublic",
      },
      {
        opt: "Private - Only specific people",
        subOpt: "Can only be viewed or joined by invitation",
        value: "private",
      },
    ],
    pageName: "DebatePreference",
  },
  {
    headLine: "Create a Debate",
    baseLine: "Office-party",
    page: 3,
    extraDetail: [
      {
        opt: "Select Categories",
        subOpt: "",
        value: "categories",
      },
    ],
    pageName: "AddPhoto",
  },
  {
    headLine: "Create a Debate",
    baseLine: "",
    page: 4,
    pageName: "AddPeople",
  },
  {
    headLine: "Invite Member",
    baseLine: "",
    pageName: "InviteMember",
  },
  {
    headLine: "Create Debate",
    baseLine: "",
    page: 5,
    pageName: "RoundSelection",
  },
  {
    headLine: "Create Debate",
    baseLine: "",
    pageName: "AddPlan",
  },
];

export const NewsPlans = [
  {
    title: "Basic",
    price: "2",
    duration: "1 month",
  },
  {
    title: "Standard",
    price: "5",
    duration: "3 month",
    isRecommended: true,
  },
  {
    title: "Premium",
    price: "8",
    duration: "6 month",
  },
];

export const DebateOptions = [
  {
    label: "Create Debate",
    route: "/create-debate",
    state:{
      MotionOrClaim: "",
      CreatorOpeningArgument: "",
      DebateImage: null,
      Fields: [],
      Type: "blitz",
      IsOtheruserAllow: false,
      SideForDebate: null,
      IsPublishWithoutOpponent: false,
      IsDebateSemiPublic: false,
      IsDebatePublic: true,
      IsDebatePrivate: false,
      NominateUserId: null,
      InvitedUserId: null,
      FileSize: 0,
      CustomRounds: null,
      CustomTimeFrame: null,
      CustomeMaxVotesToWin: null,
    }
  },
  {
    label: "Debate an Invite",
    route: "/notification",
  },
  {
    label: "Find a Motion",
    route: "/find-motion",
  },
  {
    label: "Suggest a Motion",
    route: "/suggest-motion",
  },
];

export const PlanDetails = [
  {
    title: "DebateBase Premium",
    value: "Monthly",
    features: [
      {
        title: "Unlimited Discussions",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Members",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Categories",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Tags",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Replies",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Comments",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
    ],
  },

  {
    title: "DebateBase Basic",
    value: "Forever",
    features: [
      {
        title: "Unlimited Discussions",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Members",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Categories",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Tags",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Replies",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Comments",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
    ],
  },
  {
    title: "DebateBase Advanced",
    value: "Yearly",
    features: [
      {
        title: "Unlimited Discussions",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Members",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Categories",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Tags",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Replies",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
      {
        title: "Unlimited Comments",
        description:
          "Create and join unlimited discussions with any topic. No matter what you're into, you can join any topic you want.",
      },
    ],
  },
];
export const ArgumentSuggestionsAI = [
  {
    title: "Argument 1:",
    description:
      "Realism, as an art movement that emerged in the 19th century, strives to represent subjects as they are, without idealization or distortion. Unlike other art styles, realism focuses on depicting everyday life with accuracy and authenticity. This makes it particularly effective in capturing the true essence of the world.",
  },
  {
    title: "Argument 2:",
    description:
      "Realism aims to portray subjects with meticulous detail, closely resembling their actual appearance. This attention to detail allows realism to convey a genuine and unembellished view of the world. For instance, artists like Gustave Courbet and Jean-François Millet painted scenes from rural life and working-class environments, presenting an accurate and unfiltered depiction of their subjects.",
  },
];
export const categoryValidatorUiError = `Consider using the suggested category that more align with your motion so debate is easier to find.`;

export const motionValidatorUiError = (
  <p>
    The motion or claim you have provided contains{" "}
    <span className="text-red-500  font-semibold ">
      {" "}
      grammatical errors or spelling mistakes
    </span>
    .
  </p>
);

export const imageValidatorUiError = `This image doesn't seem to fit with the motion or argument`;
export const citationValidatorUiError = `Your citation doesn't match. Here are some suggestions.`;
export const argumentSuggestionUI = `Consider this structure for your argument to make your point.`;
export const argumentStructure = (
  <>
    <p>
      1.Introduction <br />
      - Claim: Clearly state your main argument or position. <br />
    </p>
    <p>
      <>
        {" "}
        2.Body <br />- Main Points: Identify and elaborate on 2-4 key points
        that support your claim.{" "}
      </>
      Evidence: Provide data, statistics, examples, or quotes that support each
      point. with Explanation <br /> 3.Conclusion <br />- Summary: Recap the
      main points you’ve discussed.
      <br />- Call to Action (if applicable): Suggest what actions or thoughts
      the audience should take after hearing your argument.
    </p>
  </>
);
export const argumentRephrase = `People have distinct preferences for eating pizza; some fold their slices while others do not. Folding a slice can make it easier to handle and consume, particularly for those who value convenience and portability. It also helps concentrate the flavors, enhancing the overall eating experience. Conversely, eating a slice flat allows for a more balanced taste of the toppings and a traditional approach. Both methods reflect individual tastes and cultural habits.`;
export const InputFocusBlue =
  "focus:border focus:!border-debatePalette-shadow focus:!shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)] ";

export const shadowBlue =
  "!shadow-[0_1px_5px_rgba(25,_118,_210,_1)] border-blue-600";

export const shadowNormal =
  "!shadow-[0_1px_2px_rgba(128,_128,_128,_1)] border-gray-600";
export const motionSuggestionUi = [
  {
    id: 1,
    describe:
      "People have different preferences when it comes to eating pizza; some fold the slice, while others do not.",
  },
  {
    id: 2,
    describe:
      "Whether you should fold pizza when eating it depends on personal preference and the type of pizza; folding can make it easier to handle and eat, especially with large or greasy slices.",
  },
];
export const citationSuggestionUi = [
  {
    id: 1,
    CitationUrl:
      "https://wiseguypizzapie.com/cheesy-news/the-art-of-folding/",
      CitationThumbnail:"https://wiseguypizzapie.com/wp-content/uploads/2022/11/unnamed.jpg"
  },
  {
    id: 2,
    CitationUrl:
      "https://www.today.com/food/pizza-folding-debate-expert-shows-correct-way-eat-pizza-t131078",
      CitationThumbnail:""
  },
];
// eslint-disable-next-line
export const DebateSummary=`Topic: \"Transport\"\n\nParticipants:\n- Proponent: Shruti\n\nIntroduction:\nThe debate data presented here is incomplete in many aspects, including missing opponent's name, stance, and opening arguments. However, based on the available information, the topic under debate is related to transportation, and the participant identified as Shruti is labeled as the proponent.\n\nProponents' Key Arguments:\n- Shruti supports the motion, but the specific arguments or stance related to transportation is not provided in the data.\n\nOpponents' Key Arguments:\n- Due to the absence of detailed information about the opponent, their key arguments against or in favor of the motion cannot be determined.\n\nConclusion Statements:\n- Since the opponent's information and both parties' arguments are missing, a conclusion or outcome of the debate cannot be effectively summarized. This incomplete dataset leaves the debate's outcome and content largely unspecified.`

export const formatArgumentIdeas = (argument) => {
  // Extract and format the argument ideas into a numbered string
  // const ideas = argumentData.arguments.Argument_ideas;
  const numberedString = Object.entries(argument).map(([key, idea]) => (
    `${key}. ${idea}`
  )).join('\n');
  
  // Update state with the formatted string
  return numberedString;
};

