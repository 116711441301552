import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { Button } from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import MessageFileUpload from "./MessageFileUpload";

function MessageInput() {
  const textAreaRef = useRef(null);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { value } = e.target;
    setMessage(value);
  };

  const handleInput = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = "auto";
    textArea.style.height = `${textArea.scrollHeight}px`;
  };

  const handleSend = () => {
    console.log("Message sent!");
  };



  return (
    <div className="flex items-end bg-gray-100 border-secondary border rounded-lg px-4 py-2 shadow-md mx-auto">
      {/* Attachment Button */}

      <MessageFileUpload/>

      {/* Expanding Textarea */}
      <textarea
        rows={1}
        resize="false"
        placeholder="Your Message"
        onInput={handleInput}
        onChange={handleChange}
        ref={textAreaRef}
        className="flex-1 !border-0 min-h-full focus:border-transparent resize-y border-none outline-none bg-transparent text-base max-h-36 overflow-y-auto py-1"
        style={{ height: "auto" }}
        id="message-input"
      />

      {/* Send Button */}
      <Button
        variant="text"
        className="text-gray-500 p-1 text-xl ml-3 focus:outline-none"
        onClick={handleSend}
        disabled={!message}
        id="message-send"
      >
        <PaperAirplaneIcon className="size-6 text-secondary" />
      </Button>
  
    </div>
  );
}

export default MessageInput;
