import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Button, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserAvtarUpload from "../../Components/UI/AvatarStack/UserAvtarUpload";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import InputField from "../../Layout/InputField";
// import { createWorkspaceFolder } from "../../Services/azureStorageService";
import { getInitials } from "../../Utils/Helper";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import { useAddWorkspace } from "../../Web-Hooks/Workspace/use-workspace";
import { useValidateSpaceName } from "../../Web-Hooks/use-validation";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";

const CreateProfile = () => {
  const navigate = useNavigate();
  const [profileValues, setProfileValues] = useState({
    UserName: "",
    WorkSpaceName: "",
    UserImage: "",
  });
  const [picture, setPicture] = useState(null);
  const { user } = useAuth(); // eslint-disable-next-line
  const [error, setError] = useState(false);
  const [workspaceName, setWorkSpaceName] = useState("");
  const { data: isValidWorkspaceName, isLoading: isValidatingWorkspaceName } =
    useValidateSpaceName("Work", workspaceName);
  const { mutateAsync: addWorkspaceMutate, isPending: isAddingWorkspace } =
    useAddWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace, selectWorkspaceId, selectSubWorkspace } = useSubWorkspace();
  const { data: userDetails } = useGetUserById(
    user?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  
  const googleLogin = localStorage.getItem("accessToken");

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (profileValues.WorkSpaceName) {
        setWorkSpaceName(profileValues.WorkSpaceName);
      } else {
        setWorkSpaceName("");
      }
    }, 600);

    return () => clearTimeout(debounceTimeout);
  }, [profileValues.WorkSpaceName]);

  useEffect(() => {
    if (userDetails) {
      setPicture(userDetails.userImage || "");
      setProfileValues((prevData) => ({
        ...prevData,
        UserName: userDetails.userName,
        UserImage: userDetails.userImage || "",
      }));
    }
  }, [userDetails]);

  const checkValidation = () => {
    // profileValues.name !== "" && profileValues.profilePicture !== "";
    let isValid = profileValues.WorkSpaceName !== "" ? true : false;
    isValid = profileValues.UserName !== "" ? true : false;

    setError(isValid);
  };

  // InputField Change Event
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Check if the value is empty and set error state accordingly
    const isEmpty = value.trim() === "";
    setError(isEmpty);

    // Replace spaces with dashes only if the input name is "WorkSpaceName"
    const processedValue =
      name === "WorkSpaceName" ? value.replace(/\s+/g, "-") : value;

    // Update the state based on the input name
    setProfileValues((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };

  // Image Change Event

  const handleCreateWorkspace = async (workSpace) => {
    const workspaces = new FormData();
    Object.entries(workSpace).forEach(([key, value]) => {
      workspaces.append(key, value);
    });
    if (picture) {
      workspaces.set("UserImage", picture);
    } else {
      workspaces.delete("UserImage");
    }
    workspaces.append("userId", user?.userId);

    try {
      await addWorkspaceMutate(workspaces)
        .then(async(res) => {
          // process.env.REACT_APP_FRONTEND_UPLOAD && createWorkspaceFolder(res.workspaceId, res.subworkspaceId);
          const subworkspaceList = await getSubWorkspaceByWorkId(
            res.workspaceId,
            user?.userId
          );
      
          const subworkspace = subworkspaceList?.listOfSubworkspaces[0];          
          if(subworkspace){
            selectWorkspaceId(res.workspaceId);
            selectSubWorkspace(subworkspace);
          }
          navigate("/add-colleague", {
            state: {
              workspaceId: res.workspaceId,
              subworkspaceId: res.subworkspaceId,
              ViaGoogleLoggedIn: googleLogin,
            },
          });
        })
        .catch((error) => {
          console.error("Error creating workspace or subworkspace:", error);
        });
    } catch (error) {
      console.error("Error creating workspace or subworkspace:", error);
    }
  };

  // Next button/Submit Event
  const handleNext = () => {
    if (profileValues && user) {
      localStorage.setItem("newWorkspace", true);
      const getWorkspaceInitials = getInitials(profileValues.WorkSpaceName);
      const getUserInitials = profileValues?.UserName
        ? getInitials(profileValues.UserName)
        : "DB";
      const getProfileImage = picture || profileValues?.UserImage || "";
      const workSpace = {
        UserName: profileValues?.UserName || "",
        WorkSpaceName: profileValues?.WorkSpaceName || "",
        WorkSpaceInitials: getWorkspaceInitials,
        UserImage: getProfileImage,
        UserInitials: getUserInitials,
      };
      if (workSpace) {
        handleCreateWorkspace(workSpace);
      }
    }
  };

  


  const updateUserImage = (file, url) => {
    checkValidation();
    setProfileValues((prevValues) => ({
      ...prevValues,
      UserImage: url,
    }));
    setPicture(file);
  };

  return (
    <section className="flex py-2 flex-col w-full justify-evenly gap-6">
      <Button
        variant="text"
        className="self-end p-1 px-2"
        onClick={() =>
          setProfileValues({
            WorkSpaceName: "",
          })
        }
      >
        <XMarkIcon height={25} />
      </Button>
      <div className="w-full px-8 md:!w-[60%]">
        <InputField
          isControlled={true}
          inputName="WorkSpaceName"
          value={profileValues?.WorkSpaceName}
          FieldName="What's your debatebase name?"
          labeClassName={"text-2xl mb-3 font-bold"}
          FieldType="text"
          FieldSize="lg"
          placeholder="Enter your debatebase name"
          onChange={handleChange}
          icon={
            isValidatingWorkspaceName ? (
              <Spinner />
            ) : (
              isValidWorkspaceName && <CheckIcon className="font-bold" />
            )
          }
          isError={
            isValidatingWorkspaceName
              ? false
              : !isValidWorkspaceName && workspaceName !== ""
          }
          errorMessage={
            isValidatingWorkspaceName
              ? ""
              : !isValidWorkspaceName &&
                workspaceName !== "" &&
                "debatebase name already exists"
          }
        />
      </div>
      <hr className="border-debatePalette-buttonBorder my-2" />
      <div className="w-full px-8 md:!w-[60%]">
        <InputField
          inputName="UserName"
          value={profileValues?.UserName}
          FieldName="What's your name?"
          labeClassName={"text-2xl mb-3 font-bold "}
          FieldType="text"
          FieldSize="lg"
          placeholder="Enter your name"
          onChange={handleChange}
          disable={userDetails?.userName}
        />
      </div>
      <hr className="border-debatePalette-buttonBorder my-2" />

      <div className=" flex flex-col w-full justify-center items-start gap-4 px-8 md:!w-[60%]">
        <p className="text-2xl font-bold">Your profile photo</p>
        <div className="h-32 w-32 rounded-full flex items-center justify-center">
          <UserAvtarUpload
            picture={profileValues?.UserImage}
            setPicture={updateUserImage}
            userName={profileValues?.UserName}
          />
        </div>
        {/* {!profileValues?.UserImage ? (
          <BasicButton
            variant={"outlined"}
            className={"bg-transparent   text-sm"}
            size={"sm"}
            children={"Upload Photo"}
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.click();
              }
            }}
          />
        ) : (
          <div className="flex gap-2">
            <BasicButton
              variant={"outlined"}
              className={"bg-transparent   text-sm"}
              size={"sm"}
              children={
                profileValues?.UserImage === ""
                  ? "Upload Photo"
                  : "Change Photo"
              }
              onClick={() => {
                  if(inputRef.current){
                    inputRef.current.click();
                  }
              }}
            />
            <BasicButton
              variant={"outlined"}
              className={"bg-transparent   text-sm"}
              size={"sm"}
              color={"error"}
              children={"Delete Photo"}
              onClick={handleClearUserImage}
            />
          </div>
        )} */}
        <div className="flex w-full justify-between">
          <BasicButton
            loading={isAddingWorkspace}
            color={"primary"}
            isDisable={!isValidWorkspaceName || !profileValues?.UserName}
            size={"md"}
            className={"w-36 flex justify-center text-sm"}
            children={"Next"}
            onClick={handleNext}
          />

          <BasicButton
            color={"secondary"}
            variant={"outlined"}
            size={"md"}
            className={"w-36 flex justify-center text-secondary"}
            children={"Back"}
            onClick={()=> navigate("/createworkspace")}
          />
        </div>
      </div>
    </section>
  );
};

export default CreateProfile;
