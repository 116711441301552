import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import CitationComponent from "../../Components/Functionality/CitationComponent";
import CustomizedSwitch from "../../Components/UI/Switch/CustomizedSwitch";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { usePersistedState } from "../../Services/usePersistedState";
import { useGetDebateCitationList } from "../../Web-Hooks/Debate/use-debate";
import { useUploadCitation } from "../../Web-Hooks/LibraryAPI/use-citation";
import DebateCitationList from "./DebateCitationList";

const DebateAddCitation = ({ argument }) => {
  const [allCitation, setAllCitation] = usePersistedState("AllCitation", []);

  const { debateInfo, citation, setCitation, handleStateUpdate } =
    useCreateDebateContext();
  const [query] = useSearchParams();
  const debateId = query.get("debateId");
  const from = query.get("from");
  const {
    mutateAsync: uploadCitationOnServer,
    isPending: isUploadCitationPending,
  } = useUploadCitation();
  // console.log(citation,"citation")
  const { data: citationsFromDraft } = useGetDebateCitationList(debateId);

  const memoizedCitationListFromDraft = React.useMemo(() => {
    if (!citationsFromDraft) return [];

    return citationsFromDraft.map((c) => ({
      citationId: c?.citationId,
      citationUrl: c?.citationUrl,
      isCitationFromDatabase: c?.isCitationFromDatabase,
      citationNote: c?.citationNote,
      citationType: c?.citationFileType,
      citationSide: c?.bySide,
    }));
  }, [citationsFromDraft]);

  useEffect(() => {
    if (from === "draft" || from === "pending") {
      if (memoizedCitationListFromDraft?.length > 0) {
        setCitation(memoizedCitationListFromDraft);
        localStorage.setItem(
          "Citation",
          JSON.stringify(memoizedCitationListFromDraft)
        );
        setAllCitation(memoizedCitationListFromDraft);
      }
    } else {
      return;
    }
  }, [debateId, memoizedCitationListFromDraft, setCitation, from, setAllCitation]);

  useEffect(() => {
    if (allCitation?.length && debateInfo?.SideForDebate && (from === "draft" || from === "pending")) {
      const citationForSelectedSide = allCitation?.filter(
        (cite) =>
          cite?.citationSide?.toLowerCase() ===
          debateInfo?.SideForDebate?.toLowerCase()
      );
      setCitation(citationForSelectedSide);
      localStorage.setItem("Citation", JSON.stringify(citationForSelectedSide));
    }
  }, [debateInfo, from, setCitation]);

  const handleCitationSubmit = async (selectedCitation) => {
    selectedCitation.citationSide = debateInfo?.SideForDebate

    const isAlreadyAdded = citation?.some(
      (cite) => cite?.citationUrl === selectedCitation?.citationUrl
    );
    if (isAlreadyAdded) {
      return;
    }
    if (selectedCitation?.citationFrom === "external") {
      setCitation([...citation, selectedCitation]);
      (from === "draft" || from === "pending") && setAllCitation([...allCitation, selectedCitation]);
      return;
    }
    if (!selectedCitation.citationFile) {
      const isAlreadyAdded = citation?.some(
        (cite) => cite?.citationUrl === selectedCitation?.citationUrl
      );
      if (!isAlreadyAdded) {
        setCitation([...citation, selectedCitation]);
        localStorage.setItem(
          "Citation",
          JSON.stringify([...citation, selectedCitation])
        );
        (from === "draft" || from === "pending") && setAllCitation([...allCitation, selectedCitation]);
      } else {
        setCitation([...citation]);
        (from === "draft" || from === "pending") && setAllCitation([...allCitation]);

        localStorage.setItem("Citation", JSON.stringify([...citation]));
      }
      return;
    }
    const formdata = new FormData();
    formdata.append("file", selectedCitation.citationFile);
    try {
      const { data } = await uploadCitationOnServer(formdata);
      setCitation([
        ...citation,
        {
          ...selectedCitation,
          citationUrl: data.data,
          citationSide: debateInfo.SideForDebate,
        },
      ]);
      (from === "draft" || from === "pending") && setAllCitation([
        ...allCitation,
        {
          ...selectedCitation,
          citationUrl: data.data,
          citationSide: debateInfo.SideForDebate,
        },
      ]);

      localStorage.setItem(
        "Citation",
        JSON.stringify([
          ...citation,
          {
            ...selectedCitation,
            citationUrl: data.data,
            citationSide: debateInfo.SideForDebate,
          },
        ])
      );
    } catch (error) {
      toast.error("Error uploading citation", error);
    }
  };
  // useEffect(() => {
  //   console.log(citation,"citation")
  // }, [citation]);
  return (
    <div className="w-full">
      {/* <div
        className={`${
          debateInfo?.IsDebatePrivate ? "hidden" : debateInfo?.Fields?.length > 0 ? "flex" : "hidden"
        } gap-2 items-center justify-center cursor-pointer`}
      >
        <CheckCircleIcon color="green" className="h-8 w-8" />
        <span className="text-debatePalette-shadow text-lg">
          Category Selected
        </span>
      </div> */}
      <div
        className={`flex sm:flex-row flex-col items-center ${
          debateInfo?.IsDebatePrivate || from === "pending"
            ? "justify-end"
            : "justify-between"
        } w-full`}
      >
        {/* <div
          className={`${
            debateInfo?.IsDebatePrivate ?  debateInfo?.Fields?.length > 0 ? "flex" : "hidden" : "hidden"
          } gap-2 items-center cursor-pointer`}
        >
          <CheckCircleIcon color="green" className="h-8 w-8" />
          <span className="text-debatePalette-shadow text-lg">
            Category Selected
          </span>
        </div> */}
        {debateInfo?.IsDebatePrivate || from === "pending" ? null : (
          <CustomizedSwitch
            value={debateInfo?.IsOtheruserAllow}
            onChange={(e) =>
              handleStateUpdate({
                ...debateInfo,
                IsOtheruserAllow: e.target.checked,
                IsPublishWithoutOpponent: e.target.checked,
              })
            }
            textClass={"text-debatePalette-shadow text-md"}
            circleClass={"bg-debatePalette-buttonBorder"}
            text={"Allow for open Debate"}
            containerClass={"bg-debatePalette-shadow"}
            mainClassName={"border-none"}
          />
        )}
        <CitationComponent
          from={
            from === "Motion"
              ? ["motion", "library", "gallary", "device", "external"]
              : ["library", "gallary", "device", "external"]
          }
          isLoadingFromHandleSubmit={isUploadCitationPending}
          handleCitationSubmit={async (file) =>
            await handleCitationSubmit(file)
          }
          debateDetails={debateInfo}
          query={"isFromDebate=true"}
          citation={citation}
          key={"addArgument"}
          MenuHandlerComponent={
            <Button
              variant="text"
              className="px-3 py-2 min-w-fit flex gap-2 items-center justify-end"
              disabled={citation.length >= 2 || !debateInfo?.SideForDebate}
            >
              <PlusCircleIcon className="min-h-8 min-w-8 h-8 w-8 text-debatePalette-buttonBorder" />
              <span className="text-[#008BFA] text-nowrap text-base font-normal lowercase first-letter:uppercase">
                Add a citation
              </span>
            </Button>
          }
        />
      </div>

      {citation.length > 0 && (
        <DebateCitationList
          citation={citation}
          setCitation={setCitation}
          argument={argument}
        />
      )}
    </div>
  );
};

export default DebateAddCitation;
