import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import CustomSlider from "../../Components/Functionality/CustomSlider";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { prepareFormData, ValidateRequiredFields } from "../../Utils/Helper";
import {
  useAddCitation,
  useAddDebateToSubWorkspace,
  useGetDebateDetails,
} from "../../Web-Hooks/Debate/use-debate";
import AddArgumentWithAi from "./../CreateDebate/NewCreateDebate/AddArgumentWithAi";
import AddCategoryWithAI from "./../CreateDebate/NewCreateDebate/AddCategoryWithAI";
import AddCitationWithAI from "./../CreateDebate/NewCreateDebate/AddCitationWithAI";
import AddMotionWithAI from "./../CreateDebate/NewCreateDebate/AddMotionWithAI";
import { getItem } from "../../Utils/localStorage";
import { RemoveCitation } from "../../api/debate/debate";

const UpdateDebate = () => {
  const navigate = useNavigate();
  const { debateInfo, handleStateUpdate, citation } = useCreateDebateContext();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user } = useAuth();
  const [error, setError] = useState({
    CreatorOpeningArgument: "",
    MotionOrClaim: "",
    Type: "",
    Fields: "",
    SideForDebate: "",
  });
  const { mutateAsync: addDebate, isPending: isAddDebatePending } =
    useAddDebateToSubWorkspace();
  const { mutateAsync: addCitation, isPending: isAddCitationPending } =
    useAddCitation();

  const location = useLocation();
  const selectedDebate = location?.state?.selectedDebate;
  const [query] = useSearchParams();
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const { data: debateDetail } = useGetDebateDetails(debateId, user?.userId);

  useEffect(() => {
    if (debateId) {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: Array.isArray(selectedDebate?.Fields)
          ? selectedDebate?.Fields
          : selectedDebate?.Fields?.split(";").map((field) => field.trim()),
        ScalerPoint: debateDetail?.userSideForDebateScalerPoint
          ? JSON.parse(debateDetail?.userSideForDebateScalerPoint)
          : 0,
        CustomRounds:
          debateDetail?.type === "custom"
            ? debateDetail?.totalRound || null
            : null,
        CustomTimeFrame: debateDetail?.timeFrame || null,
        CustomeMaxVotesToWin: debateDetail?.maxVotesToWin || null,
      });
    } // eslint-disable-next-line
  }, [debateDetail]);

  const fieldsToValidate = [
    {
      name: "SideForDebate",
      displayName: "Argument",
      errorMessage: "Please select side for debate",
    },
    {
      name: "CreatorOpeningArgument",
      displayName: "Argument",
      errorMessage: "Please add a creator opening argument",
    },
    {
      name: "MotionOrClaim",
      displayName: "Debate Type",
      errorMessage: "Please enter a motion or claim",
    },
    {
      name: "Type",
      displayName: "Debate Type",
      errorMessage: "Please select a debate type",
    },
    {
      name: "Fields",
      displayName: "Categories",
      errorMessage: "Please add at least one field",
    },
  ];

  const handlePublish = async (draft = false) => {
    const draftFieldsToValidate = fieldsToValidate.filter(
      (field) =>
        field.name !== "CreatorOpeningArgument" &&
        field.name !== "SideForDebate"
    );
    try {
      const isFormValidated = ValidateRequiredFields(
        draft ? draftFieldsToValidate : fieldsToValidate,
        debateInfo,
        setError
      );
      if (!isFormValidated) return;
      const formData = prepareFormData(
        from === "draft"
          ? {
              ...debateInfo,
              debateId: debateId,
            }
          : debateInfo,
        selectedSubWorkspace,
        user,
        true
      );
      await handleDebateAndCitationAPI(formData);
    } catch (error) {
      console.error("Error in handlePublish:", error);
      toast.error(
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleDebateAndCitationAPI = async (formData) => {
    const allCitations = getItem("AllCitation");
    if (allCitations.length) {
      const citationsToRemove = allCitations?.filter(
        (cite) =>
          cite?.citationSide?.toLowerCase() !==
          debateInfo?.SideForDebate?.toLowerCase()
      );
      if (citationsToRemove?.length) {
        // Remove each citation from the database if it’s saved
        citationsToRemove.forEach((citationToRemove) => {
          const citationData = {
            CitationId: citationToRemove.citationId,
            UserId: user?.userId,
          };

          // Check for valid CitationId and UserId before removing
          if (
            !Object.values(citationData).includes(null) &&
            !Object.values(citationData).includes(undefined) &&
            !Object.values(citationData).includes("")
          ) {
            RemoveCitation(citationData);
          }
        });
      }
    }
    try {
      const data = await addDebate(formData);
      if (data && citation?.length > 0) {
        const filterCitation = citation.filter(
          (item) => !item.isCitationFromDatabase
        );
        if (filterCitation?.length > 0) {
          await addCitation({
            InvitationId: data?.invitationId ? data?.invitationId : null,
            ArgumentId: data?.argumentId ? data?.argumentId : null,
            debateId: data?.debateId,
            userId: user?.userId,
            subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
            bySide: debateInfo?.SideForDebate,
            round: debateInfo?.Type === "eonian" ? 0 : 1,
            citations: filterCitation,
          });
        }
      }
      navigate(
        debateInfo?.IsDebatePublic
          ? "/feed-public"
          : debateInfo?.IsDebateSemiPublic
          ? "/feed"
          : "/private"
      );
    } catch (error) {
      console.error("Error in handleDebateAndCitationAPI:", error);
      toast.error(
        "An error occurred while publishing the debate. Please try again."
      );
    } finally {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
      localStorage.removeItem("AllCitation");
    }
  };

  useEffect(() => {

    return () => {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
      localStorage.removeItem("AllCitation");
    }
  }, []);

  const isDataAdded =
    debateInfo?.MotionOrClaim &&
    debateInfo?.CreatorOpeningArgument &&
    debateInfo?.SideForDebate &&
    debateInfo?.Fields?.length > 0;

  return (
    <Container>
      <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 ">
        <DebateHeader
          content={{
            headLine: "Update Debate",
            page: debateInfo?.Type === "custom" ? 4 : 3,
          }}
        />
        <div className="w-full flex flex-col gap-4">
          <AddCategoryWithAI />
          <AddMotionWithAI error={error} setError={setError} />
          <AddArgumentWithAi error={error} setError={setError} />
          <AddCitationWithAI />
          <div className="flex flex-col items-center gap-6 py-2">
            <CustomSlider
              debateInfo={debateInfo}
              handleStateUpdate={handleStateUpdate}
            />
            <DebateChip
              option={[{ label: "Disagree" }, { label: "Agree" }]}
              label={
                debateInfo?.SideForDebate
                  ? debateInfo?.SideForDebate?.toLowerCase() === "agree"
                    ? "Agree"
                    : "Disagree"
                  : ""
              }
              setLabel={() => {}}
              // setLabel={(vote) => {
              //   handleStateUpdate({ ...debateInfo, SideForDebate: vote });
              //   setError((prev) => ({ ...prev, SideForDebate: "" }));
              // }}
            />
            {error?.SideForDebate && (
              <p className="text-red-500 text-sm font-semibold">
                {error.SideForDebate}
              </p>
            )}
          </div>
          <div className="flex flex-col mt-4 sm:flex-row items-center justify-end w-full">
            <Button
              className={`rounded-md flex items-center justify-center text-debatePalette-title ${
                isDataAdded ? "bg-primary" : "bg-gray-400"
              }`}
              onClick={() => handlePublish(false)}
              disabled={!isDataAdded}
              loading={isAddDebatePending || isAddCitationPending}
            >
              <span className="text-center">Update</span>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UpdateDebate;
