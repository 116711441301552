import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../../Layout/HomeLayout";
import InvitationLinkExpired from "../../Layout/InvitationLinkExpired";
import NewWorkspaceLayout from "../../Layout/NewWorkspaceLayout";
import PaymentGateWay from "../../Services/Stripe-Payment/PaymentGateWay";
import AddName from "../CreateDebate/AddName";
import AddPeople from "../CreateDebate/AddPeople";
import AddPhoto from "../CreateDebate/AddPhoto";
import AddPlan from "../CreateDebate/AddPlan";
import AddVisibility from "../CreateDebate/AddVisibility";
import CreateDebate from "../CreateDebate/CreateDebate";
import InviteMember from "../CreateDebate/InviteMember";
import InviteViewers from "../CreateDebate/InviteViewers";
import CreateNewDebate from "../CreateDebate/NewCreateDebate/CreateDebate";
import RoundSelection from "../CreateDebate/RoundSelection";
import Categories from "../DebateCategories/Categories";
import Debated from "../Home/Debated";
import Home from "../Home/Home";
import Private from "../Home/Private";
import PublicDebated from "../Home/PublicDebated";
import PublicHome from "../Home/PublicHome";
import PublicVoted from "../Home/PublicVoted";
import Voted from "../Home/Voted";
import Library from "../Library/Library";
import Message from "../Messaging/Message";
import NewMessage from "../Messaging/NewMessage";
import FindMotion from "../Motion/FindMotion";
import SuggestMotion from "../Motion/SuggestMotion";
import News from "../News/News";
import MainNotification from "../Notification/MainNotification";
import AddArgument from "../Posts/AddArgument";
import AddInvites from "../Posts/AddInvites";
import DebateDetail from "../Posts/DebateDetail";
import ActiveDebateResults from "../Profile/ActiveDebateResults";
import ActiveDraftsTabs from "../Profile/ActiveDraftsTabs";
import DebateResultTabs from "../Profile/DebateResultTabs";
import FollowerTabs from "../Profile/FollowerTabs";
import MySaveTab from "../Profile/MySaveTab";
import OpenDebate from "../Profile/OpenDebate";
import UserProfile from "../Profile/UserProfile";
import AddColleagues from "../Public/AddColleagues";
import CreateProfile from "../Public/CreateProfile";
import Join from "../Public/Join";
import SignIn from "../Public/SignIn";
import SignUp from "../Public/SignUp";
import SignUpMain from "../Public/SignUpMain";
import WorkspaceHome from "../Public/WorkspaceHome";
import Reference from "../Reference/Reference";
import RoleManagment from "../RoleManagement/RoleManagment";
import SearchTabs from "../Search/SearchTabs";
import FollowerSuggestion from "../Suggestions/FollowerSuggestion";
import ProtectedRoute from "./ProtectedRoutes";
import UpdateDebate from "../UpdateDebate/UpdateDebate";

export const router = createBrowserRouter([
  {
    // parent route component
    element: (
      <ProtectedRoute>
        <HomeLayout />
      </ProtectedRoute>
    ),
    // child route components
    children: [
      {
        path: "/feed",
        element: <Home />,
      },

      {
        path: "/feed-public",
        element: <PublicHome />,
      },
      {
        path: "/debated-public",
        element: <PublicDebated />,
      },
      {
        path: "/debated-public/:profileId",
        element: <PublicDebated />,
      },
      {
        path: "/voted-public",
        element: <PublicVoted />,
      },
      {
        path: "/voted-public/:profileId",
        element: <PublicVoted />,
      },
      {
        path: "/debate/:debateId",
        element: <DebateDetail />,
      },
      // other pages....
      {
        path: "/debated",
        element: <Debated />,
      },
      {
        path: "/reference",
        element: <Reference />,
      },
      {
        path: "/debated/:profileId",
        element: <Debated />,
      },
      {
        path: "/debate/:debateId/add-argument",
        element: <AddArgument />,
      },
      {
        path: "/debate/:debateId/add-invite",
        element: <AddInvites />,
      },
      {
        path: "/voted",
        element: <Voted />,
      },
      {
        path: "/voted/:profileId",
        element: <Voted />,
      },
      {
        path: "/private",
        element: <Private />,
      },
      {
        path: "/private/:profileId",
        element: <Private />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/search",
        element: <SearchTabs />,
      },
      {
        path: "/profile/:profileId",
        element: <UserProfile />,
      },
      {
        path: "/message/:profileId",
        element: <Message />,
      },
      {
        path: "/message",
        element: <NewMessage />,
      },
      {
        path: "notification",
        element: <MainNotification />,
      },
      {
        path: "suggest-motion",
        element: <SuggestMotion />,
      },
      {
        path: "find-motion",
        element: <FindMotion />,
      },
      {
        path: "profile/active-drafts",
        element: <ActiveDraftsTabs />,
      },
      {
        path: "debate-results/:debateId",
        element: <ActiveDebateResults />,
      },
      {
        path: "profile/follower-management",
        element: <FollowerTabs />,
      },
      {
        path: "profile/debate-result",
        element: <DebateResultTabs />,
      },
      {
        path: "profile/open-debate",
        element: <OpenDebate />,
      },
      {
        path: "debate-results/:debateId",
        element: <ActiveDebateResults />,
      },
      {
        path: "profile/mysave",
        element: <MySaveTab />,
      },
      {
        path: "library",
        element: <Library />,
      },
      {
        path: "role",
        element: <RoleManagment />,
      },
      {
        path: "/update-debate",
        element: (
          <ProtectedRoute>
            <CreateDebate />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "",
            element: <UpdateDebate />,
          },
        ],
      },
      {
        path: "/create-debate",
        element: (
          <ProtectedRoute>
            <CreateDebate />
          </ProtectedRoute>
        ),
        children: [
          {
            path: "", // Changed to relative path
            element: <CreateNewDebate />,
          },
          {
            path: "debate-type", // Changed to relative path
            element: <AddName />,
          },
          {
            path: "add-visibility",
            element: <AddVisibility />,
          },
          {
            path: "add-photo",
            element: <AddPhoto />,
          },
          {
            path: "add-people",
            element: <AddPeople />,
          },
          {
            path: "add-plan",
            element: <AddPlan />,
          },
          {
            path: "plan-payment",
            element: <PaymentGateWay />,
          },
          {
            path: "round-selection",
            element: <RoundSelection />,
          },
          {
            path: "invite-member",
            element: <InviteMember />,
          },
          {
            path: "invite-viewers",
            element: <InviteViewers />,
          },
        ],
      },
    ],
  },
  {
    // parent route component
    // child route components
    children: [
      {
        path: "/join",
        element: <Join />,
      },
      {
        path: "/",
        element: <SignIn />,
      },
      {
        path: "/signup",
        element: <SignUpMain />,
      },
      {
        path: "/otp-login",
        element: <SignUp />,
      },
      {
        path: "/createworkspace",
        element: (
          <ProtectedRoute>
            <WorkspaceHome />
          </ProtectedRoute>
        ),
      },
      {
        path: "/invitation-expired",
        element: <InvitationLinkExpired />,
      },
    ],
  },
  {
    element: (
      <ProtectedRoute>
        <NewWorkspaceLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/createprofile",
        element: <CreateProfile />,
      },
      {
        path: "/add-colleague",
        element: <AddColleagues />,
      },
      {
        path: "/categories",
        element: <Categories />,
      },
      {
        path: "/selectfollow",
        element: <FollowerSuggestion />,
      },
    ],
  },
]);
