import {
  BuildingLibraryIcon,
  DocumentIcon,
  PaperClipIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { Fragment, useRef, useState } from "react";
import SelectFromLibraryPopup from "../../Components/Popup/SelectFromLibraryPopup";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import ImageCropperSRP from "../../Components/Functionality/ImageCropperSRP";
import { useUploadCitationUserWise } from "../../Web-Hooks/LibraryAPI/use-citation";

const MessageFileUpload = () => {
  const [selectFromLibraryPopup, setSelectFromLibraryPopup] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [selectedCitation, setSelectedCitation] = useState({});
  const [imageCropperPopup, setImageCropperPopup] = useState(false);
  const imageCropperRef = useRef(null);
  const { mutateAsync: uploadFile, isPending: isUploading } =
    useUploadCitationUserWise();

  const Options = [
    {
      id: "gallary",
      SVG: <PhotoIcon className="size-5 text-inherit" />,
      label: "Gallary",
      value: "gallary",
      handleClick: () => {
        imageCropperRef.current.click();
      },
    },
    {
      id: "file",
      SVG: <DocumentIcon className="size-5 text-inherit" />,
      label: "File",
      value: "file",
      handleClick: async () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = `video/mp4,.doc,.docx,.pdf`; // Added image/* for all image types
        input.click();
        await new Promise((resolve) => {
          input.onchange = (e) => {
            resolve(e.target.files[0]);
            if (e.target.files[0]) {
              setSelectedCitation((prev) => ({
                ...prev,
                citationTitle: e.target.files[0].name,
                citationUrl: e.target.files[0].name,
                citationType: e.target.files[0].name.split(".")?.pop(),
                citationFile: e.target.files[0],
                citationFrom: "device",
              }));
              setConfirmationPopup(true);
            }
          };
        });
      },
    },
    {
      id: "library",
      SVG: <BuildingLibraryIcon className="size-5 text-inherit" />,
      label: "Library",
      value: "library",
      handleClick: () => {
        setSelectFromLibraryPopup(true);
      },
    },
  ];

  const handleUploadFile = async () => {
    const data = await uploadFile(selectedCitation?.citationFile);
    console.log(data);
  };

  const handleAttachmentClick = () => {
    setSelectFromLibraryPopup(true);
  };
  return (
    <Fragment>
      <ImageCropperSRP
        inputRef={imageCropperRef}
        setModalOpen={setImageCropperPopup}
        modalOpen={imageCropperPopup}
        maxCropSize={500}
        type={`debate`}
        updateAvatar={(file, url) => {
          setSelectedCitation({
            ...selectedCitation,
            citationTitle: file.name,
            citationUrl: url,
            citationType: "image",
            citationFile: file,
            citationFrom: "gallary",
            citationNote: "image",
          });
          setConfirmationPopup(true);
        }}
        closeModal={() => setImageCropperPopup(false)}
        resetState={() => {
          setSelectedCitation({
            citationUrl: "",
            citationNote: "",
            citationType: "",
            citationFile: null,
          });
        }}
      />
      <EditableBodyPopup
        open={confirmationPopup}
        handleClose={() => setConfirmationPopup(false)}
        btn1={"Cancel"}
        btn2={"Send"}
        loading={isUploading}
        title={"Attach File"}
        onClickBtn1={() => {
          setConfirmationPopup(false);
          setSelectedCitation({});
        }}
        onClickBtn2={async() => {
          await handleUploadFile();
          setConfirmationPopup(false);
          setSelectedCitation({});
        }}
      >
        <div className="flex flex-col gap-2 items-center">
          <p className="text-lg text-debatePalette-bodyText">
            Do you want to attach the file?
          </p>
          <p>{selectedCitation?.citationTitle}</p>
        </div>
      </EditableBodyPopup>
      <SelectFromLibraryPopup
        setCitationPopup={() => {}}
        setSelectedCitation={setSelectedCitation}
        libraryPopup={selectFromLibraryPopup}
        setLibraryPopup={setSelectFromLibraryPopup}
        fromTO={"Add Photo"}
        handleSubmit={(citation) => console.log(citation)}
      />
      <Menu placement="top-start">
        <MenuHandler>
          <Button
            variant="text"
            className="text-gray-500 p-1 text-xl mr-3 focus:outline-none"
            onClick={handleAttachmentClick}
          >
            <PaperClipIcon stroke="2" className="size-6 text-secondary" />
          </Button>
        </MenuHandler>
        <MenuList className="min-w-40 bg-secondary text-debatePalette-main !z-10000000">
          {Options?.map((option) => (
            <MenuItem
              onClick={option.handleClick}
              className="flex items-center gap-2 hover:bg-gray-100"
            >
              {option.SVG}
              <p className="text-md font-semibold text-inherit py-2">
                {option.label}
              </p>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Fragment>
  );
};

export default MessageFileUpload;
