import { Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";
import { useTab } from "../../../Context/TabContext";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { useNavigate } from "react-router-dom";
import { useUpdateNotificationStatus } from "../../../Web-Hooks/Notification/use-notification";
import { useAuth } from "../../../Context/AuthContext";
import { getDebateDetails } from "../../../api/debate/debate";

const NotificationCard = ({
  notification,
  setNotification,
  setRejectPopup,
  setNominatePopup,
}) => {
  const { setTab } = useTab();
  const navigate = useNavigate();
  const { mutateAsync: updateNotificationStatus } =
    useUpdateNotificationStatus();
  const { user } = useAuth();
  const statusObj = {
    NotificationId: notification?.notificationId,
    IsActionTaken: true,
  };

  const handleNotificationTypeUI = (notification, type) => {
    if (type === "cronjobnominate" && notification?.isUserView === false) {
      return (
        <span>
          -{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setNominatePopup(true);
              setNotification(notification);
            }}
          >
            Nominate
          </span>{" "}
        </span>
      );
    }
    if (type === "Reject" && notification?.isUserView === false) {
      return (
        <span>
          {" "}
          - would you like to make this debate{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setRejectPopup(true);
              setNotification(notification);
            }}
          >
            Open
          </span>{" "}
          or{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              setNominatePopup(true);
              setNotification(notification);
            }}
          >
            Nominate
          </span>{" "}
          other user
        </span>
      );
    } else if (type === "eonian") {
      return (
        <span>
          - would you like to add new argument?{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={(e) => {
              e.stopPropagation();
              updateNotificationStatus(statusObj);
              navigate(`/profile/active-drafts?selected=active`);
            }}
          >
            Click here
          </span>
        </span>
      );
    } else if (
      type === "Subworkspacejoin" &&
      notification?.isUserView === false
    ) {
      return (
        <span>
          - would you like to join this forum?{" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              await updateNotificationStatus(statusObj);
              window.open(`${notification?.urlToJoin}`, "_self");
              // window.open(`http://localhost:3000/join?${notification?.urlToJoin?.split('join?')?.pop()}`, "_self");
            }}
          >
            Join now
          </span>
        </span>
      );
    } else if (type === "NextRound" && notification?.isUserView === false) {
      return (
        <span>
          {" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              const debateDetails = await getDebateDetails(
                notification.debateId,
                user?.userId
              );
              if (user?.userId === debateDetails?.opponentId) {
                navigate(
                  `/debate/${notification.debateId}/add-argument?userName=Add Argument`
                );
              } else {
                navigate(
                  `/debate/${notification.debateId}?round=${debateDetails?.currentRound}`
                );
              }
            }}
          >
            Click here
          </span>
        </span>
      );
    } else if (type === "NextRound" && notification?.isUserView === true) {
      return (
        <span>
          {" "}
          <span
            className="underline text-debatePalette-link hover:text-secondary"
            onClick={async (e) => {
              e.stopPropagation();
              const debateDetails = await getDebateDetails(
                notification.debateId,
                user?.userId
              );
              navigate(
                `/debate/${notification.debateId}?round=${debateDetails?.currentRound}`
              );
            }}
          >
            View details
          </span>
        </span>
      );
    }
  };

  const handleNotificationClick = async (notification, type) => {
    const isHandledExternally = [
      "Reject",
      "Subworkspacejoin",
      "cronresultopen",
      "NextRound",
    ].includes(type);

    if (!isHandledExternally) {
      await updateNotificationStatus({
        NotificationId: notification.notificationId,
        IsActionTaken: true,
      });
    }

    const debateDetails = await getDebateDetails(
      notification.debateId,
      user?.userId
    );

    switch (type) {
      case "cronresult":
        navigate(`/debate-results/${notification.debateId}`);
        break;
      case "cronjobsavedraft":
        navigate(`/profile/active-drafts?selected=draft`);
        break;
      case "Like":
      case "Vote":
      case "cronjobopendebate":
      case "cronresultopen":
      case "Accept":
        navigate(
          `/debate/${notification.debateId}?round=${
            debateDetails?.type === "eonian" ? 0 : debateDetails?.currentRound
          }`,
          {
            state: {
              category: debateDetails?.fields,
              debateType: debateDetails?.type,
            },
          }
        );
        break;
      case "nomination" || "cronjobnominate":
        setTab("nomination");
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div
        className={`flex flex-col w-full ${
          notification?.isUserView ? "" : "opacity-100 bg-gray-200"
        } shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] rounded-md border gap-8 justify-items-center items-center my-4 cursor-pointer`}
      >
        <Card
          className={`w-full ${
            notification?.isUserView ? "" : "opacity-100 bg-gray-200"
          }`}
          shadow={false}
          onClick={() =>
            handleNotificationClick(
              notification,
              notification?.notificationType
            )
          }
        >
          <CardBody className="flex flex-col sm:flex-row gap-2 items-center !p-3">
            <div className="inline-flex items-center justify-center w-[6%] ">
              <ImageOrInitials
                initials={notification?.senderName || "Anonymous"}
                imageSrc={notification?.senderImage}
              />
            </div>
            <div className="flex-1 text-wrap truncate">
              <Typography
                variant="h6"
                color="black"
                className="text-center sm:text-left !line-clamp-1"
              >
                {notification?.title}
              </Typography>
              <Typography
                variant="paragraph"
                className="text-justify sm:text-left "
                color="black"
              >
                {notification?.text}
                {handleNotificationTypeUI(
                  notification,
                  notification?.notificationType
                )}
              </Typography>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default NotificationCard;
