import moment from "moment";
import toast from "react-hot-toast";

export function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string.slice(1);
}

export function extractPathname(str) {
  const route = str.slice(1).split("/");
  return route;
}

export const ValidateRequiredFields = (
  fieldsToValidate,
  formData,
  setError
) => {
  const missingFields = fieldsToValidate.filter(
    (field) =>
      !formData[field.name] ||
      (field.name === "Fields" && formData.Fields.length === 0)
  );

  if (missingFields.length > 0) {
    const errorMessage =
      "Please fill " +
      missingFields
        .map((field) => {
          setError((prevError) => ({
            ...prevError,
            [field.name]: field.errorMessage,
          }));
          return field.displayName;
        })
        .join(", ");
    toast.error(errorMessage);
    return false;
  } else {
    return true;
  }
};

export const prepareFormData = (
  debateInfo,
  selectedSubWorkspace,
  user,
  draft,
  futureAction = ""
) => {
  const {
    Type,
    CustomeMaxVotesToWin,
    CustomTimeFrame,
    CustomRounds,
    NominateUserId,
    IsPublishWithoutOpponent,
    Fields,
  } = debateInfo;

  return {
    ...debateInfo,
    DebateImage: debateInfo?.DebateImage?.split("/")?.pop(),
    CustomeMaxVotesToWin: Type === "custom" ? CustomeMaxVotesToWin : null,
    CustomTimeFrame: Type === "custom" ? CustomTimeFrame : null,
    CustomRounds: Type === "custom" ? CustomRounds : null,
    SubWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
    CreatedUserId: user?.userId,
    Fields: Array.isArray(Fields) ? Fields?.join(";") : Fields,
    IsPublishWithoutOpponent: draft
      ? false
      : NominateUserId === null
      ? true
      : IsPublishWithoutOpponent,
    IsOtheruserAllow: draft
      ? false
      : NominateUserId === null
      ? true
      : IsPublishWithoutOpponent,
    CreatedAt: getUTCDate(),
    AfterCronIsOpen: futureAction === "open",
    AfterCronIsSaveAsDraft: futureAction === "save",
    AfterCronIsNominateOtherUser: futureAction === "invite",
  };
};
export const getFileType = (name) => {
  const fileName = name.toLowerCase();
  if (
    fileName.endsWith(".jpeg") ||
    fileName.endsWith(".jpg") ||
    fileName.endsWith(".png")
  ) {
    return "image";
  } else if (fileName.endsWith(".doc") || fileName.endsWith(".docx")) {
    return "doc";
  } else if (fileName.endsWith(".pdf")) {
    return "pdf";
  } else if (
    fileName.endsWith(".mp4") ||
    fileName.endsWith(".mov") ||
    fileName.endsWith(".avi")
  ) {
    return "video";
  } else {
    // Assume it's a URL or handle other file types as needed
    // toast.error("File type not supported");
    return;
  }
};

export const getFileNameFromURL = (url) => {
  const urlParts = url.split("/");
  const fileName = urlParts[urlParts.length - 1];
  return fileName;
};

export function convertDateToUTC(date) {
  return moment(date).utc().format();
}
// Intials from Name
export function getInitials(name) {
  if (!name) return "";

  if (name.includes("@")) {
    // Handle email: extract initials from the username part (first 2 chars)
    const username = name.split("@")[0];
    return username
      .slice(0, 2)
      .toUpperCase()
      .replace(/[^a-zA-Z0-9]/g, "");
  }

  if (name.includes("-")) {
    // Handle hyphenated names
    const words = name.split("-");
    const initials = words
      .map((word) =>
        word
          .trim(" ")
          .charAt(0)
          .toUpperCase()
          .replace(/[^a-zA-Z0-9]/g, "")
      )
      .join("");
    return initials.slice(0, 2); // Take only the first 2 initials
  }

  if (name.includes(" ")) {
    // If the input contains spaces, assume it's a full name
    const words = name.split(" ");
    const initials = words
      .map((word) =>
        word
          .charAt(0)
          .toUpperCase()
          .replace(/[^a-zA-Z0-9]/g, "")
      )
      .join("");
    return initials.slice(0, 2); // Take only the first 2 initials
  }

  // For single-word names, take the first and middle initials
  const firstInitial = name
    .charAt(0)
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Take the first character and convert to uppercase
  const middleInitial = name
    .charAt(Math.floor(name.length / 2))
    .toUpperCase()
    .replace(/[^a-zA-Z0-9]/g, ""); // Take the middle character and convert to uppercase
  return `${firstInitial}${middleInitial}`;
}
// Initials from email
export function getInitialsFromEmail(email) {
  const atIndex = email?.indexOf("@");
  if (atIndex !== -1) {
    let name = email?.substring(0, atIndex);
    return getInitials(name);
  } else {
    return getInitials(email);
  }
}

export function getNameFromEmail(email) {
  const atIndex = email?.indexOf("@");
  if (atIndex !== -1) {
    return email?.substring(0, atIndex);
  } else {
    return email;
  }
}

// Based on return from date today or yesterday
export function isTodayOrYesterday(date) {
  const parsedDate = moment(date, "YYYY-MM-DD");

  // Check if the date is valid
  if (!parsedDate.isValid()) {
    // Return an error message
    return "Invalid date format";
  }

  // Get the current date using moment.js
  const today = moment();

  // Compare the date with the current date
  if (parsedDate.isSame(today, "day")) {
    // Return "today"
    return "Today";
  } else if (parsedDate.isSame(today.subtract(1, "day"), "day")) {
    // Return "yesterday"
    return "Yesterday";
  } else {
    // Format the date to MM-DD-YYYY using moment.js
    const formattedDate = parsedDate.format("MM-DD-YYYY");
    return formattedDate;
  }
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const buildFormData = (data, excludedFields) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (!excludedFields.includes(key)) {
      formData.append(key, value);
    }
  });
  return formData;
};

export const copyToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!");
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const getLocalTime = (time) => {
  const now = moment(time).local().fromNow();
  return now;
};

export function isValidUrl(string) {
  const regex = /^(https?|ftp|file|blob|data):\/\/([^\s$.?#].[^\s]*)$/i;
  return regex.test(string);
}

export function getWordCount(text) {
  // Replace all newlines with spaces to treat newlines as word boundaries
  return text.trim().split(/\s+/).filter(Boolean).length;
}

// Helper function to trim text to the word limit
export const trimToWordLimit = (text, wordLimit) => {
  // Replace all newlines with spaces
  const wordsArray = text.replace(/\n/g, " ").trim().split(/\s+/);

  // Trim the text to the word limit
  return wordsArray.slice(0, wordLimit).join(" ");
};

export const getUTCDate = () => {
  const now = moment.utc().format();
  return now;
};

export function sortByKey(list, key) {
  return list?.sort((a, b) => {
    const nameA = a[key].toUpperCase(); // ignore upper and lowercase
    const nameB = b[key].toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0; // names must be equal
  });
}

export function objectToFormData(obj) {
  const formData = new FormData();

  // Recursive function to handle nested objects
  function appendFormData(data, formData, rootKey) {
    if (Array.isArray(data)) {
      data.forEach((value, index) => {
        appendFormData(value, formData, `${rootKey}[${index}]`);
      });
    } else if (typeof data === "object" && data !== null) {
      Object.keys(data).forEach((key) => {
        if (rootKey) {
          appendFormData(data[key], formData, `${rootKey}.${key}`);
        } else {
          appendFormData(data[key], formData, key);
        }
      });
    } else {
      // Convert boolean values to FormData as they are
      formData.append(rootKey, data);
    }
  }

  // Start appending recursively
  appendFormData(obj, formData, "");

  return formData;
}

export function convertStringToArray(inputString) {
  // Ensure inputString is a string, if not, default to an empty string
  const validString = inputString || "";

  // Check if the string contains '&'
  if (validString.includes(",")) {
    return validString
      .split(",") // Split the string by '&'
      .map((item) => item.trim()); // Trim any extra whitespace
  } else {
    // If '&' is not present, return the input string as a single-item array
    return validString ? [validString.trim()] : [];
  }
}
export function convertArrayToString(input) {
  // If input is not an array, return it as is
  if (!Array.isArray(input)) {
    return input;
  }

  // Ensure all elements in the array are strings
  if (!input.every(item => typeof item === 'string')) {
    // You might want to handle this case differently depending on your needs
    throw new Error('All elements in the array must be strings');
  }

  // Convert the array to a comma-separated string
  return input.filter(item => item.trim()).join(',');
}
export const normalizeResponse = (response) => { if (!response ) {
  return false;
}
const isMatch = (value) => {
    return (
      value === "matched" ||
      value === "Matched" ||
      value === true ||
      value === "Yes"
    );
  };
  if ( response.Matched || response.Result||response?.result) {
    const matched = response.Matched || response.Result||response?.result;
    return isMatch(matched);
  }

  // Function to determine if a value indicates a match

  // Check if the response is an object with multiple categories
  return Object.keys(response).some((key) => {
    const categoryInfo = response[key];
    if (typeof categoryInfo === "object") {
      const matched = categoryInfo.Matched || categoryInfo.Result;
      return isMatch(matched);
    }
    return false;
  });
};
export function FindCommonSubstrings(str1, str2) {
  const m = str1.length;
  const n = str2.length;
  const dp = Array(m + 1).fill(null).map(() => Array(n + 1).fill(0));
  let maxLength = 0;
  let endIndexStr1 = 0;

  // Fill the DP table
  for (let i = 1; i <= m; i++) {
    for (let j = 1; j <= n; j++) {
      if (str1[i - 1] === str2[j - 1]) {
        dp[i][j] = dp[i - 1][j - 1] + 1;
        if (dp[i][j] > maxLength) {
          maxLength = dp[i][j];
          endIndexStr1 = i;
        }
      } else {
        dp[i][j] = 0;
      }
    }
  }
  // Extract the longest common substring
  const startIndex = endIndexStr1 - maxLength;
  return str1.substring(startIndex, endIndexStr1);
}

export function CountWords(text) {
  // Trim the text to remove any leading or trailing whitespace
  text = text?.trim();

  // Check if the text is empty
  if (text?.length === 0) {
    return 0;
  }

  // Split the text by whitespace and filter out any empty strings
  const words = text?.split(/\s+/);

  // Return the number of words
  return words?.length;
}

export const argumentIdeas = ( content ) => {
// Check if content contains numbered items
const hasNumberedItems = /\d+\.\s/.test(content);

// If content has numbered items, format it
if (hasNumberedItems) {
  return content
    .replace(/\n/g, '<br />'); // Converts newlines into <br /> tags
}

// Return content as-is if no numbering is detected
return content;
};

export const hotTopicBorder = 'border-y-4 border-r-2  border-l-4 border-primary p-2 rounded-2xl'
