import { Input } from "@material-tailwind/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import {
  otpInputStyleError,
  otpInputStyleNormal,
  otpInputStyleSuccess,
} from "../../Utils/Constant";

const OTPField = ({
  length = 6,
  handleSubmit,
  setLoader,
  email,
  failedCount,
  setFailedCount,
  workId,
  subWorkId,
}) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const { verifyOTP } = useAuth();

  const inputRef = useRef([]);
  useEffect(() => {
    if (inputRef.current[0]) {
      inputRef.current[0].focus();
    }
  }, []);

  const handleValidation = async (enteredOtp) => {
    try {
      const res = await verifyOTP(enteredOtp, email, workId, subWorkId);
      if (res) {
        await Promise.all(
          inputRef.current.map(async (input) => {
            input.blur();
            input.classList.remove(otpInputStyleNormal);
            input.classList.add(otpInputStyleSuccess);
          })
        );
        setTimeout(() => {
          handleSubmit(res);
        }, 1000);
      } else {
        await Promise.all(
          inputRef.current.map(async (input) => {
            input.blur();
            input.classList.remove(otpInputStyleSuccess);
            input.classList.add(otpInputStyleError);
            setTimeout(() => {
              input.classList.remove(otpInputStyleError);
              input.classList.add(otpInputStyleNormal);
              setOtp(new Array(length).fill(""));
            }, 1000);
          })
        );
        setTimeout(() => {
          const count = failedCount === 4;
          if (!count) {
            inputRef.current[0].focus();
          }
        }, 1000);
        setFailedCount((prevCount) => prevCount + 1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (index, e) => {
    const value = e.target.value;

    // If the input value is not a number, return early
    if (isNaN(value)) return;

    // If the input value length is equal to or greater than the OTP length
    if (value.length >= length) {
      const newOtp = value.slice(0, length).split("");
      setOtp(newOtp);

      // Submit trigger
      const combinedOtp = newOtp.join("");
      if (combinedOtp.length === length) {
        handleValidation(combinedOtp);
      }

      // Change focus of input
      if (inputRef.current[length - 1]) {
        inputRef.current[length - 1].focus();
      }

      return;
    }

    // Handle normal single digit input
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    // Submit trigger
    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      handleValidation(combinedOtp);
    }

    // Change focus of input to the next one if it's not the last input
    if (value && index < length - 1 && inputRef.current[index + 1]) {
      inputRef.current[index + 1].focus();
    }

    // If the current input is filled and the next input is empty, focus on the next empty input
    if (value && inputRef.current[newOtp.indexOf("")]) {
      inputRef.current[newOtp.indexOf("")].focus();
    }
  };
  const handleClick = (index) => {
    inputRef.current[index].setSelectionRange(1, 1);

    // it moves focus to the empty field
    if (index > 0 && !otp[index - 1]) {
      inputRef.current[otp.indexOf("")].focus();
    }
  };
  const handleKey = (index, e) => {
    // Backspace to shift previous
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRef.current[index - 1]
    ) {
      inputRef.current[index - 1].focus();
    }
  };
  return (
    <div className="w-full flex justify-center gap-0.5 xs:gap-2">
      {otp.map((number, i) => (
        <Fragment key={i}>
          <Input
            id={i + number}
            inputRef={(input) => {
              inputRef.current[i] = input;
            }}
            containerProps={{
              className: "!min-w-0 !border-none",
            }}
            value={number}
            onChange={(e) => handleChange(i, e)}
            onClick={(e) => handleClick(i)}
            onKeyDown={(e) => handleKey(i, e)}
            className={`!w-full !max-w-8 sm:!max-w-16 md:!max-w-16 lg:!max-w-16 !border-t-0 !border-r-0 !border-l-0 !border-b-[3px] rounded-none !text-center !text-sm md:!text-2xl  focus:!border-b-black !px-0 ${otpInputStyleNormal}`}
            labelProps={{
              className: "before:content-none after:content-none",
              htmlFor: i + number,
            }}
          />
          {/* <InputField
            value={number}
            key={i}
            refer={(input) => {
              inputRef.current[i] = input;
            }}
            containerClassName={"!min-w-0 !border-none"}
            className={
              `!w-full !max-w-8 sm:!max-w-16 md:!max-w-16 lg:!max-w-16 !border-t-0 !border-r-0 !border-l-0 !border-b-[3px] rounded-none !text-center !text-sm md:!text-2xl  focus:!border-b-black !px-0 ${otpInputStyleNormal}`
            }
            FieldName=""
            FieldSize="md"
            onChange={(e) => handleChange(i, e)}
            onClick={(e) => handleClick(i)}
            onKeyDown={(e) => handleKey(i, e)}
          /> */}
          {i === 2 && <span className="text-2xl mx-2">-</span>}
        </Fragment>
      ))}
    </div>
  );
};

export default OTPField;
