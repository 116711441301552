import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React from "react";

const MessageMenu = ({ options }) => {
  return (
    <Menu placement="left-end" className="flex flex-col bg-white">
      <MenuHandler>
        {
          <EllipsisVerticalIcon
            id="message-menu"
            className="w-6 h-6 cursor-pointer"
          />
        }
      </MenuHandler>
      <MenuList className={`p-3  !bg-secondary min-w-[120px] gap-2  z-75 `}>
        {options?.map((option) => (
          <MenuItem
            key={option?.id}
            id={option?.id}
            className="flex items-center w-auto text-debatePalette-background px-2 py-1 justify-center gap-3 z-0"
            onClick={() => option?.handleClick(option?.value)}
          >
            {option.SVG && <option.SVG className={`h-4 w-4 `} />}
            <p
              className={`text-md font-semibold text-inherit py-2 ${
                option?.isRedText && "!text-debatePalette-timer"
              }`}
            >
              {option?.label}
            </p>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default MessageMenu;
