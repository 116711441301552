import { Avatar, Button } from "@material-tailwind/react";
import React from "react";
import { isTodayOrYesterday } from "../../Utils/Helper";
import NoData from "../../Components/Functionality/NoData";
import TitleDivider from "./TitleDivider";
import MessageCard from "./MessageCard";

const MessageScreen = ({ messageContent, user }) => {
  return (
    <div className="h-full w-full">
      <section className={`${messageContent?.length > 0 ? "h-auto" : "h-full"} flex flex-col justify-end`}>
        {messageContent?.length > 0 ? (
          messageContent.map((message, index) => (
            <React.Fragment key={message.id}>
              <TitleDivider title={isTodayOrYesterday(message?.date)} />

              {message?.messageDetails.map((message, index) => (
                <MessageCard message={message} key={message.messageId} />
              ))}
            </React.Fragment>
          ))
        ) : (
          <React.Fragment>
            <div className="my-8 h-full">
              <NoData
                size={"h-[80%] w-full"}
                children={
                  <Button className="bg-secondary shrink-0">Say Hello</Button>
                }
                message="No messages yet"
              />{" "}
              {/* <div className=" mr-4  flex gap-4 items-end">
                <Avatar
                  src="https://docs.material-tailwind.com/img/face-2.jpg"
                  alt="avatar"
                  size="lg"
                />
                <div className="flex flex-col items-start justify-center ">
                  <p className="text-4xl font-semibold capitalize">
                    {extractPathname(user)[1]}
                  </p>
                  <p className="text-xl text-debatePalette-bodyText font-medium ">
                Start conversation saying hi to{" "}
                <span className="capitalize">{extractPathname(user)[1]}</span>
              </p>
                </div>
              </div> */}
            </div>
          </React.Fragment>
        )}
      </section>
    </div>
  );
};

export default MessageScreen;
