import { Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import NoData from "../../Components/Functionality/NoData";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import Container from "../../Components/UI/Container";
import { ThreeDotMenu } from "../../Components/UI/Menus/ThreeDotMenu";
import { useAuth } from "../../Context/AuthContext";
import { useProfile } from "../../Context/openProfileContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useAddFollowing } from "../../Web-Hooks/Profile/use-following";
import { useGetOtherUserDetailsById } from "../../Web-Hooks/Profile/use-profile";
import UserDebates from "./UserDebates";

const UserProfile = () => {
  const location = useLocation();
  const { pathname } = location;
  const otherUserId = pathname?.split("/")?.pop();
  const { selectedWorkspace } = useWorkspace();
  const { data: user, isLoading: isUserLoading } = useGetOtherUserDetailsById(
    otherUserId,
    selectedWorkspace?.workSpaceId
  );
  const [following, setFollowing] = useState(user?.isFollowing);
  const { mutate: handleFollow } = useAddFollowing();
  const { user: loggedInUser } = useAuth();
  const { setOpenRight } = useProfile();

  useEffect(()=>{
    setFollowing(user?.isFollowing)
  },[user])

  if (!user && !isUserLoading) {
    return <NoData message={"User not exist"} />;
  }

  const handleFollowing = (e, followingId) => {
    e.stopPropagation();
    handleFollow({
      Type: !following ? "follow" : "unfollow",
      FollowingUserId: followingId,
      FollowerUserId: loggedInUser?.userId,
      IsFollow: true,
    });
    setFollowing(!following);
  };
  return (
    <Container>
      <div className="w-full flex justify-center">
        <div className="lg:w-[75%] flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row justify-start gap-3 sm:gap-10 items-center md:items-end">
            <div className="flex flex-col gap-3">
              <ImageOrInitials
                imageSrc={user?.userImage ? user?.userImage : null}
                initials={user?.userName || user?.email}
                size={"h-32 w-32"}
                classNameInitials={
                  "ring-4 text-7xl ring-primary ring-offset-[3px]"
                }
                classNameImage={"ring-4 ring-primary ring-offset-[3px]"}
              />
              <Typography className="text-2xl sm:w-32 text-center font-semibold">
                {user?.userName || " "}
              </Typography>
            </div>

            <div className="flex flex-col gap-5 justify-end w-max">
              <div className="flex gap-10">
                {" "}
                <div className="flex flex-col gap-2 items-center">
                  <Typography className="text-xl font-semibold">
                    {user?.followersCount || 0}
                  </Typography>
                  <Typography className="text-xl font-semibold">
                    Followers
                  </Typography>
                </div>
                <div className="flex flex-col gap-2 items-center">
                  <Typography className="text-xl font-semibold">
                    {user?.followingsCount || 0}
                  </Typography>
                  <Typography className="text-xl font-semibold">
                    Followings
                  </Typography>
                </div>
              </div>
              <div className="flex items-center gap-3">
                {user?.userId !== loggedInUser?.userId ? (
                  <div className="flex gap-2 w-full">
                    <BasicButton
                      variant={"filled"}
                      color={"primary"}
                      onClick={(e) => handleFollowing(e, user?.userId)}
                      className="flex-grow justify-center text-debatePalette-title"
                    >
                      {following || user?.isFollowing ? "Unfollow" : "Follow"}
                    </BasicButton>
                    {/* <BasicButton
                      variant={"filled"}
                      color={"secondary"}
                      onClick={(e) => handleFollowing(e, user?.userId)}
                      className="flex-grow justify-center bg-sideLayoutSecondary text-debatePalette-background"
                    >
                      {"Message"}
                    </BasicButton> */}
                  </div>
                ) : (
                  <BasicButton
                    color={"primary"}
                    onClick={(e) => setOpenRight(true)}
                    className="flex-grow justify-center text-debatePalette-title"
                  >
                    View Profile
                  </BasicButton>
                )}
                <div className="lg:hidden">
                  <ThreeDotMenu
                    user={otherUserId}
                    debateId={null}
                    type={"user"}
                  />
                </div>
              </div>
            </div>
            <div className="lg:flex flex-col hidden justify-center w-max">
              <ThreeDotMenu user={otherUserId} debateId={null} type={"user"} />
            </div>
          </div>
          <div className="flex w-full justify-center gap-10 items-center">
            {/* <Typography className="text-2xl hidden sm:block sm:w-32 text-center font-semibold">
              {user?.userName}
            </Typography> */}
            {/* <div className="flex sm:flex-1">
              <BasicButton
                color={user?.isFollowing ? "gray" : "primary"}
                onClick={() => {}}
                className="w-max"
              >
                {user?.isFollowing ? "Following" : "Follow"}
              </BasicButton>
            </div> */}
          </div>
          <UserDebates otherUserId={otherUserId} />
        </div>
      </div>
    </Container>
  );
};

export default UserProfile;
