import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { getWorkspaceByUserId } from "../../api/workspace/workspace";
import Email from "../../Assets/Svg/Email.svg";
import Logo from "../../Assets/Svg/Logo.svg";
import Outlook from "../../Assets/Svg/Outlook.svg";
import OTPField from "../../Components/Functionality/OTPField";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { OTPInfo, findCode } from "../../Utils/Constant";
// import { createUserFolder } from "../../Services/azureStorageService";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";

export default function SignUp() {
  const navigate = useNavigate();
  const { login, initiateOTP } = useAuth();
  const [loader, setLoader] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const location = useLocation();
  // const { pathname } = location;
  const { userData } = location?.state;
  const [failedCount, setFailedCount] = useState(0);
  // const [captcha, setCaptcha] = useState(null);
  // const recaptchaRef = useRef(null);
  const { search: searchParams } = location;
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectWorkspaceId } = useSubWorkspace();
  const query = new URLSearchParams(searchParams);
  // const queryEmail = query?.get("Email");
  const queryWorkspaceId = query?.get("WorkspaceId");
  const querySubWorkspaceId = query?.get("SubWorkspaceId");

  // Login Functionality
  const loginFn = async (res) => {
    // e.preventDefault();
    if (res) {
      // process.env.REACT_APP_FRONTEND_UPLOAD && createUserFolder(res?.userId);
      login(res);
      if (queryWorkspaceId) {
        const workspace = await getWorkspaceByUserId(res?.userId);
        const findOne = workspace?.listWorkspaces.find(
          (workspace) => workspace.workSpaceId === queryWorkspaceId
        );

        if (findOne) {
          selectWorkspace(findOne);
          const subworkspaceList = await getSubWorkspaceByWorkId(
            queryWorkspaceId,
            res?.userId
          );
          const findSubWorkspaceFromUserSubWorkspaceList = (subWorkspaceId) => {
            return subworkspaceList?.listOfSubworkspaces?.find(
              (subWorkspace) => subWorkspace.subWorkSpaceId === subWorkspaceId
            );
          }
      
          const subworkspace = findSubWorkspaceFromUserSubWorkspaceList(querySubWorkspaceId);
          if (subworkspace) {
            selectWorkspaceId(queryWorkspaceId);
            selectSubWorkspace(subworkspace);
          }
          navigate("/feed", { replace: true });
        } else {
          navigate("/createworkspace", {
            state: {
              userId: res?.userId,
              workspaceId: queryWorkspaceId,
              subworkspaceId: querySubWorkspaceId,
            },
          });
        }
        setLoader(false);
        return;
      }
      navigate("/createworkspace", { replace: true });
      setLoader(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds >= 1) {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [seconds]);

  const handleResendOTP = async (e) => {
    e.preventDefault();
    const res = initiateOTP(userData?.email);
    if (res) {
      setSeconds(60);
    }
  };

  // async function onChange(value) {

  //   setCaptcha(value);
  //   setFailedCount(0);
  //   recaptchaRef.current.reset()
  // }

  useEffect(() => {
    if (!userData) {
      navigate("/", { replace: true });
    } // eslint-disable-next-line
  }, []);

  return (
    <section className="flex justify-center items-center md:h-screen bg-white">
      {loader ? (
        <LoadingSpinner color="#ffc107" height={120} width={120} />
      ) : (
        <Card className=" flex-col justify-center md:w-[600px] md:max-h-[600px] shadow-none gap-4 px-4 md:px-10 py-3  border border-debatePalette-buttonBorder">
          <CardHeader
            variant="gradient"
            color="transparent"
            className="grid max-h-20 mt-2  place-items-center shadow-none !static"
          >
            <img src={Logo} alt="" className="max-h-20" />
          </CardHeader>
          <CardBody className="flex flex-col gap-4 p-0">
            <div className="flex flex-col text-center justify-center items-center gap-2">
              <p className="text-5xl text-debatePalette-title font-semibold">
                {OTPInfo.headLine}
              </p>
              <p className="w-full text-debatePalette-bodyText sm:w-96">
                {OTPInfo.subLine}
                {userData?.email}
                {OTPInfo.subLine2}
              </p>
            </div>
            {/* SignUp OTP Form */}
            <form className="max-w-[100%] py-5">
              <div className="flex flex-col w-full justify-center items-center gap-4 p-0">
                <div className="mb-1 flex flex-col w-[90%] items-stretch gap-6 my-5">
                  <div className="flex justify-center w-full">
                    {
                      // !(failedCount === 5) &&
                      <OTPField
                        email={userData?.email}
                        length={6}
                        handleSubmit={(e) => {
                          loginFn(e);
                        }}
                        failedCount={failedCount}
                        setFailedCount={setFailedCount}
                        setLoader={setLoader}
                        workId={queryWorkspaceId}
                        subWorkId={querySubWorkspaceId}
                      />
                    }
                  </div>
                  {/* <div className={`flex justify-center w-full ${(failedCount === 5 && !captcha) ? "block" : "hidden"}`}>
                    <ReCAPTCHA
                     ref={recaptchaRef}
                      sitekey={`${process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}`}
                      onChange={onChange}
                    />
                  </div> */}
                  <div
                    className={`flex flex-col sm:flex-row justify-end items-end `}
                  >
                    <div className="flex items-center justify-between w-full text-end gap-3">
                      <p>Still not received OTP?</p>

                      <div className="flex gap-2 items-center">
                        {seconds !== 0 && (
                          <div
                            id="counter"
                            className="text-red-600 text-sm font-semibold"
                          >
                            00:{seconds < 10 ? "0" : ""}
                            {seconds}
                          </div>
                        )}
                        <Button
                          disabled={seconds !== 0}
                          variant="text"
                          ripple={false}
                          className="flex gap-2 p-1 capitalize hover:bg-transparent "
                          onClick={handleResendOTP}
                        >
                          <p
                            className={`cursor-pointer flex font-normal text-sm ${
                              seconds !== 0
                                ? "text-debatePalette-bodyText"
                                : "text-debatePalette-link"
                            } hover:text-secondary`}
                          >
                            Resend OTP
                          </p>
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row justify-center items-center gap-2 sm:gap-8">
                    <Button
                      className="p-2 m-0 flex items-center justify-center gap-2"
                      variant="text"
                      onClick={() =>
                        window.open(
                          "https://mail.google.com/mail/u/0/?ogbl#inbox"
                        )
                      }
                    >
                      <img src={Email} alt="" className="h-5 w-5" />{" "}
                      <span className="text-xs font-normal capitalize text-debatePalette-bodyText">
                        Open Gmail
                      </span>
                    </Button>
                    <Button
                      className="p-2 m-0 flex items-center justify-center gap-2"
                      variant="text"
                      onClick={() =>
                        window.open("https://outlook.live.com/mail/0/")
                      }
                    >
                      <img src={Outlook} alt="" className="h-5 w-5" />{" "}
                      <span className="text-xs font-normal capitalize text-debatePalette-bodyText">
                        Open Outlook
                      </span>
                    </Button>
                  </div>
                  <div className="pt-0 flex flex-col  w-full justify-center items-center">
                    <p className={`flex justify-center text-center`}>
                      {findCode}
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </CardBody>
          <CardFooter className="pt-0 text-center">
            <SignupFooter info={OTPInfo} />
          </CardFooter>
        </Card>
      )}
    </section>
  );
}
